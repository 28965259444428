// src/features/auth/authSlice.ts
import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import axiosInstance from '../../api/axiosInstance';

interface AuthState {
  isAuthenticated: boolean;
  token: string | null;
  loading: boolean;
  error: string | null;
}
const token = localStorage.getItem('authToken');
const initialState: AuthState = {
  isAuthenticated: !!token,
  token: token,
  loading: false,
  error: null,
};

// Async thunk for logging in
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ email, password }: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/login', {
        email,
        password,
      });

      return {
        token: response.data.token,
        unique_id: response.data.unique_id,
      }; // Assuming your backend returns a token
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
      state.isAuthenticated = false;
      state.token = null;
      localStorage.removeItem('authToken');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action: PayloadAction<{unique_id: string, token: string}>) => {
        state.isAuthenticated = true;
        state.token = action.payload.token;
        state.loading = false;
        localStorage.setItem('authToken', action.payload.token);
        localStorage.setItem('auth_unique_id', action.payload.unique_id);
        
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
