// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../api/axiosInstance';
import { DynamicPage, VendorState } from './dynamicPageInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';

const initialState: VendorState = {
  dynamicPages: [],
  dynamicPage: {
    title: '',
    section_type: '',
    sort_no: 0,
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};

// Async thunk for fetching buyers
export const fetchDynamicSections = createAsyncThunk(
  'dynamicSection/fetchDynamicSections',
  async ({ page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE }: { page?: number; perPage?: number }, { rejectWithValue }) => {

    try {
      const response = await axiosInstance.get(`/dynamic-page-list?page=${page}&perPage=${perPage}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAllVendors = createAsyncThunk(
  'dynamicSection/fetchAllVendors',
  async () => {

    try {
      const response = await axiosInstance.get(`/vendor-list-all`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchDynamicSection = createAsyncThunk(
  'dynamicSection/fetchDynamicSection',
  async ({ id }: { id: string }, { rejectWithValue }) => {

    try {
      const response = await axiosInstance.get(`/dynamic-page-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);


// Async thunk for creating a buyer
export const createDynamicSectionForPage = createAsyncThunk(
  'dynamicSection/createDynamicSection',
  async (formState: any, { rejectWithValue }) => {
    try {

      const { banners, field, } = formState;


      const formData = new FormData();

      for (const key in field) {
        formData.append(`${key}`, (field[key] as any));
      }

      for (const variant_key in banners) {
        const variant_: any = banners[variant_key]

        for (const key in variant_) {
          if (key === 'imageFiles' && Array.isArray(variant_[key])) {
            // If it's the files field, cast it to an array of File objects and append each file individually
            (variant_[key] as File[]).forEach((file, index) => {
              formData.append(`banners[${+variant_key}][imageFiles][${index}]`, file);
            });
          } else {
            formData.append(`banners[${+variant_key}][${key}]`, (variant_ as any)[key]);
          }
        }
      }

      const response = await axiosInstance.post('/dynamic-page-save-for-page', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const createDynamicSectionForProduct = createAsyncThunk(
  'dynamicSection/createDynamicSectionForProduct',
  async (formState: any, { rejectWithValue }) => {
    try {

      const { products, field, } = formState;

      const attributeData = {
        products,
        field
      };
      // debugger

      const response = await axiosInstance.post('/dynamic-page-save-for-product', attributeData);
      return response.data;

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const updateDynamicSectionForPage = createAsyncThunk(
  'dynamicSection/updateDynamicSectionForPage',
  async (formState: any, { rejectWithValue }) => {
    try {

      const { banners, field, } = formState;


      const formData = new FormData();

      for (const key in field) {
        formData.append(`${key}`, (field[key] as any));
      }

      for (const variant_key in banners) {
        const variant_: any = banners[variant_key]

        for (const key in variant_) {
          if (key === 'imageFiles' && Array.isArray(variant_[key])) {
            // If it's the files field, cast it to an array of File objects and append each file individually
            (variant_[key] as File[]).forEach((file, index) => {
              formData.append(`banners[${+variant_key}][imageFiles][${index}]`, file);
            });
          } else {
            formData.append(`banners[${+variant_key}][${key}]`, (variant_ as any)[key]);
          }
        }
      }

      const response = await axiosInstance.post('/dynamic-page-update-for-page', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const updateDynamicSectionForProduct = createAsyncThunk(
  'dynamicSection/updateDynamicSectionForProduct',
  async (formState: any, { rejectWithValue }) => {
    try {

      const { products, field, } = formState;

      const attributeData = {
        products,
        field
      };
      // debugger

      const response = await axiosInstance.post('/dynamic-page-update-for-product', attributeData);
      return response.data;

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteDynamicSection = createAsyncThunk(
  'dynamicSection/deleteDynamicSection',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/vendor-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const attributeSlice = createSlice({
  name: 'dynamicSection',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDynamicSections.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDynamicSections.fulfilled, (state, action: PayloadAction<PaginationObject<DynamicPage>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchDynamicSections.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllVendors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllVendors.fulfilled, (state, action: PayloadAction<DynamicPage[]>) => {
        state.dynamicPages = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllVendors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchDynamicSection.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDynamicSection.fulfilled, (state, action: PayloadAction<DynamicPage>) => {
        state.dynamicPage = action.payload;
        state.loading = false;
      })
      .addCase(fetchDynamicSection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createDynamicSectionForPage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDynamicSectionForPage.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createDynamicSectionForPage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createDynamicSectionForProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDynamicSectionForProduct.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createDynamicSectionForProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateDynamicSectionForPage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDynamicSectionForPage.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateDynamicSectionForPage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateDynamicSectionForProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDynamicSectionForProduct.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateDynamicSectionForProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteDynamicSection.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDynamicSection.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteDynamicSection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = attributeSlice.actions;

export default attributeSlice.reducer;
