import React, { useState, useRef, useEffect, ChangeEvent, FormEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faTimes } from '@fortawesome/free-solid-svg-icons';
import webSocketService from '../app/WebSocketService'; // Import the WebSocket service

interface Message {
  text: string;
  sender: 'user' | 'bot';
}

const ChatWidget: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([
    { text: "Hello! How can we assist you?", sender: 'bot' }
  ]);
  const [newMessage, setNewMessage] = useState('');
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null); // To keep track of the last message

  useEffect(() => {
    // Connect to WebSocket server
    webSocketService.connect('ws://localhost:8080');

    // Listen for incoming WebSocket messages
    webSocketService.onMessage((data) => {
      const botMessage: Message = { text: data, sender: 'bot' };
      console.log(data)
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    });

    return () => {
      // Cleanup WebSocket on component unmount
      webSocketService.socket?.close();
    };
  }, []);

  // Scroll to the bottom when messages update
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const toggleChat = () => setIsOpen(!isOpen);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value);
  };

  const handleSendMessage = (e: FormEvent) => {
    e.preventDefault();
    if (newMessage.trim()) {
      const userMessage: Message = { text: newMessage, sender: 'user' };
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      // Send message to WebSocket server
      webSocketService.sendMessage(newMessage);
      setNewMessage('');
    }
  };

  return (
    <div className="fixed bottom-4 right-4">
      <button
        onClick={toggleChat}
        className="bg-blue-600 text-white p-3 rounded-full shadow-lg"
      >
        {isOpen ? (
          <FontAwesomeIcon icon={faTimes} />
        ) : (
          <FontAwesomeIcon icon={faComments} />
        )}
      </button>

      {isOpen && (
        <div className="w-80 h-96 bg-white border border-gray-200 shadow-lg rounded-lg flex flex-col">
          <div
            ref={chatContainerRef}
            className="flex-1 overflow-y-auto border-t border-b border-gray-200 p-2 mb-4"
          >
            {messages.map((message, index) => (
              <div
                key={index}
                className={`text-sm mb-2 ${
                  message.sender === 'user' ? 'text-right text-blue-700' : 'text-left text-gray-700'
                }`}
              >
                {message.text}
              </div>
            ))}
            {/* Dummy element for auto-scroll */}
            <div ref={bottomRef} />
          </div>

          <form onSubmit={handleSendMessage} className="p-2 border-t border-gray-200">
            <input
              type="text"
              value={newMessage}
              onChange={handleInputChange}
              placeholder="Type a message..."
              className="w-full p-2 border rounded-lg outline-none focus:ring-2 focus:ring-blue-500"
            />
          </form>
        </div>
      )}
    </div>
  );
};

export default ChatWidget;
