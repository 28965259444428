// src/components/BodyClassWrapper.tsx
import React, { useEffect } from 'react';

interface BodyClassWrapperProps {
  className: string;
  children: React.ReactNode;
}

const BodyClassWrapper: React.FC<BodyClassWrapperProps> = ({ className, children }) => {
  useEffect(() => {
    // Add class to body when component mounts
    document.body.classList.add(className);

    // Remove class from body when component unmounts
    return () => {
      document.body.classList.remove(className);
    };
  }, [className]);

  return <>{children}</>;
};

export default BodyClassWrapper;
