// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { Unit, UnitState } from './unitInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField } from '../../../components/componentInterface';

const initialState: UnitState = {
  units: [],
  unit: {
    name: '',
    unit_group_name: '',
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};

// Async thunk for fetching buyers
export const fetchUnits = createAsyncThunk(
  'Unit/fetchUnits',
  async ({ page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE }: { page?: number; perPage?: number }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/unit-list?page=${page}&perPage=${perPage}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAllUnits = createAsyncThunk(
  'Unit/fetchAllUnits',
  async () => {
    
    try {
      const response = await axiosInstance.get(`/unit-list-all`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchUnit = createAsyncThunk(
  'Unit/fetchUnit',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/unit-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createUnit = createAsyncThunk(
  'Unit/createUnit',
  async (formState: Omit<Unit, 'id'>, { rejectWithValue }) => {
    try {

      const { details, name, symbol, unit_group_id, conversion_factor  } = formState.data;
      
      const attributeData = {
        name: name.value,
        symbol: symbol.value,
        unit_group_id: unit_group_id.value,
        conversion_factor: conversion_factor.value,
      };
      // debugger

      const response = await axiosInstance.post('/unit-save', attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateUnit = createAsyncThunk(
  'Unit/updateUnit',
  async (formState: Omit<Unit, 'id'>, { rejectWithValue }) => {
    try {
      debugger
      const { details, id,  name, symbol, unit_group_id, conversion_factor } = formState.data;
      
      const attributeData = {
        id: id.value,
        name: name.value,
        symbol: symbol.value,
        unit_group_id: unit_group_id.value,
        conversion_factor: conversion_factor.value,
      };
      debugger

      const response = await axiosInstance.put(`/unit-update/${attributeData.id}`, attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteUnit = createAsyncThunk(
  'Unit/deleteUnit',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/unit-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const Unitslice = createSlice({
  name: 'Unit',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnits.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUnits.fulfilled, (state, action:  PayloadAction<PaginationObject<Unit>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchUnits.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllUnits.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllUnits.fulfilled, (state, action:  PayloadAction<Unit[]>) => {
        state.units = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllUnits.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchUnit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUnit.fulfilled, (state, action:  PayloadAction<Unit>) => {
        state.unit = action.payload;
        state.loading = false;
      })
      .addCase(fetchUnit.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createUnit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUnit.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createUnit.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateUnit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUnit.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateUnit.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteUnit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUnit.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteUnit.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = Unitslice.actions;

export default Unitslice.reducer;
