// src/utils/idb.ts
import { openDB, IDBPDatabase } from 'idb';
import { MenuItem } from '../../modules/auth/menuInterface';
import { IDX_MENU_DB, IDX_MENU_STORE, IDX_STORE_LIST } from './../URL/student-information';

let dbInstance: IDBPDatabase | null = null;

const initDB = async () => {
    // Increment the database version to trigger the upgrade if the store is missing
    dbInstance = await openDB(IDX_MENU_DB, 9, { // Incremented version
        upgrade(db) {
            db.createObjectStore('metaStore');
            // Check and create the necessary store based on storeName
            IDX_STORE_LIST.map(STORE => {
                if (!db.objectStoreNames.contains(STORE)) {
                    const store = db.createObjectStore(STORE, { keyPath: 'id' });
                    
                    // Add indexes based on store name
                    if (STORE === IDX_MENU_STORE) {
                        store.createIndex('parent_id', 'parent_id');
                        store.createIndex('module_name', 'module_name');
                    }
                }
            })
            
        },
    });

    return dbInstance;
};

export { initDB };
