import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/store"; // Adjust path as per your project structure
import {
  fetchMenuItems,
  selectMenuItems,
} from "../../modules/auth/menuSlice";
import { AppDispatch } from "../../app/store";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import SkeletonLoader from "../../components/Skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faCircleDot,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "../../components/theme";
import SidebarBranding from "./SidebarBranding";
import { toTitleCase } from "../../app/utils";
import pusherService from "../../app/pusherService";
import { saveMenuData } from "../../app/idb/menuOperations";
import { MENU_UPDATED_EVENT } from "../../app/URL/student-information";

interface SidebarProps {
  isOpen: boolean;
  onChangeRoute: (segment: string) => void;
  onMouseHoverOnCloseSidebar: (condition: boolean) => void;
  moduleName: string;
}

const Sidebar: React.FC<SidebarProps> = ({
  isOpen,
  onChangeRoute,
  moduleName,
  onMouseHoverOnCloseSidebar,
}) => {
  const dispatch: AppDispatch = useDispatch();
  
  const menuItems = useSelector((state: RootState) => selectMenuItems(state));
  const loading = useSelector((state: RootState) => state.menu.loading);
  const error = useSelector((state: RootState) => state.menu.error);
  const location = useLocation();
  const [firstSegment, setFirstSegment] = useState<string>("");
  const [isClosedSidebarOpen, setClosedSidebarOpen] = useState<boolean>(false);
  const [activeSubMenu, setActiveSubMenu] = useState<number | null>(null);
  const subMenuRefs = useRef<Record<number, HTMLUListElement | null>>({});

  const { menuColor, textColor, hoverBGColor, hoverTextColor, setModuleName } = useTheme();

  useEffect(() => {
    if (firstSegment !== "") {
      onChangeRoute(firstSegment);
      dispatch(fetchMenuItems({ firstSegment }));
      setModuleName(toTitleCase(firstSegment))
    }
  }, [firstSegment]);

  useEffect(() => {
    const segment = location.pathname.split("/")[1];
    console.log("menuItems", segment, menuItems);

    setFirstSegment(segment);
  }, [location]);

  useEffect(() => {
    const loadData = async () => {
     
    };
    loadData();

    const handleMenuUpdate = async (data: any) => {
      console.log('Received new menu data:', data.menu);
      await saveMenuData(data.menu)
      const firstSegment = localStorage.getItem('module_name')?.toLowerCase() as string;
  
      // Now passing the correct parameter
      dispatch(fetchMenuItems({ firstSegment }));
    };

    pusherService.subscribe(MENU_UPDATED_EVENT, handleMenuUpdate);

    return () => {
      pusherService.unsubscribe(MENU_UPDATED_EVENT, handleMenuUpdate);
    };
  }, []);

  const toggleSubMenu = (event: React.MouseEvent, id: number) => {
    event.preventDefault();
    setActiveSubMenu(activeSubMenu === id ? null : id);
  };

  const handleMouseOut = (event: React.MouseEvent) => {
    event.preventDefault();
    if (!isOpen && isClosedSidebarOpen) {
      onMouseHoverOnCloseSidebar(!isClosedSidebarOpen);
      setClosedSidebarOpen(false);
    }
  };

  const handleMouseHover = (event: React.MouseEvent) => {
    event.preventDefault();
    if (!isOpen && !isClosedSidebarOpen) {
      onMouseHoverOnCloseSidebar(!isClosedSidebarOpen);
      setClosedSidebarOpen(true);
    }
  };

  return (
    <aside
      onMouseEnter={handleMouseHover}
      onMouseLeave={handleMouseOut}
      className={`bg-${menuColor} dark:bg-colorBlack sidebar ${
        isOpen ? "open" : isClosedSidebarOpen ? "open" : "closed"
      } ${moduleName}`}
    >
      <SidebarBranding
        isOpen={isOpen}
        isClosedSidebarOpen={isClosedSidebarOpen}
      />

      <ul className="sidebar-nav mt-2">
        {loading && <SkeletonLoader />}
        {error && <p>Error: {error}</p>}
        {!loading &&
          menuItems.length > 0 &&
          menuItems.map((item) => (
            <li key={item.id} className="nav-item">
              {item.child ? (
                <>
                  <a
                    className={`nav-link collapsed text-${textColor} dark:text-colorGray hover:bg-${hoverBGColor} hover:text-${hoverTextColor} dark:hover:bg-colorBlack1`}
                    href="#"
                    onClick={(event) => toggleSubMenu(event, item.id)}
                  >
                    <FontAwesomeIcon icon={faHome} className="mr-2" />
                    <span
                      className={`${
                        isOpen || isClosedSidebarOpen ? "block" : "hidden"
                      }`}
                    >
                      {item.name}
                    </span>
                    <FontAwesomeIcon
                      icon={
                        activeSubMenu === item.id
                          ? faChevronDown
                          : faChevronRight
                      }
                      className="ml-2"
                    />
                  </a>
                  <ul
                    ref={(el) => (subMenuRefs.current[item.id] = el)}
                    className={`nav-content ${
                      activeSubMenu === item.id || isClosedSidebarOpen
                        ? "expanded"
                        : "collapsed"
                    }`}
                    id={`submenu-${item.id}`}
                  >
                    {item.child.map((subItem) => (
                      <li key={subItem.id}>
                        <Link
                          to={subItem.route || "#"}
                          className={`hover:bg-${hoverBGColor} dark:hover:bg-colorBlack1 dark:text-colorGray ${
                            subItem.active ? "active" : ""
                          } text-${textColor} hover:text-${hoverTextColor}`}
                        >
                          <FontAwesomeIcon
                            icon={faCircleDot}
                            className="mr-2"
                          />
                          <span
                            className={`${
                              isOpen || isClosedSidebarOpen ? "block" : "hidden"
                            }`}
                          >
                            {subItem.name}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <Link
                  className={`nav-link hover:bg-${hoverBGColor} hover:text-${hoverTextColor} dark:hover:bg-colorBlack1 text-${textColor} dark:text-colorGray`}
                  to={item.route || "#"}
                >
                  <i className={item.icon}></i>
                  <span>{item.name}</span>
                </Link>
              )}
            </li>
          ))}
      </ul>
    </aside>
  );
};

export default Sidebar;
