// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { Courier, CourierExpenses, CourierState } from './courierInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField } from '../../../components/componentInterface';

const initialState: CourierState = {
  couriers: [],
  courier: {
    total_cost: 0,
    name: '',
    type: '',
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};

// Async thunk for fetching buyers
export const fetchCouriers = createAsyncThunk(
  'courier/fetchCouriers',
  async ({ page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE }: { page?: number; perPage?: number }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/courier-list?page=${page}&perPage=${perPage}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAllCouriers = createAsyncThunk(
  'courier/fetchAllCouriers',
  async () => {
    
    try {
      const response = await axiosInstance.get(`/courier-list-all`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchCourier = createAsyncThunk(
  'courier/fetchCourier',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/courier-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createCourier = createAsyncThunk(
  'courier/createCourier',
  async (formState: Omit<Courier, 'id'>, { rejectWithValue }) => {
    try {

      const { details, name, ...formStateData } = formState.data;

      const attributeData = {
        name: name.value,
        rows: details?.rows.map((row : any) => {
          const newRow: Partial<CourierExpenses> = {
            expense_name: '',
            cost: 0,
          };

          row?.map((cell : any) => {
            if (cell.name === 'expense_name') {
              newRow.expense_name = cell.type.value as string;
            } 
            if (cell.name === 'cost') {
              newRow.cost = cell.type.value as number;
            } 
          })

          return newRow;

        }) || []
      };
      debugger

      const response = await axiosInstance.post('/courier-save', attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateCourier = createAsyncThunk(
  'courier/updateCourier',
  async (formState: Omit<Courier, 'id'>, { rejectWithValue }) => {
    try {
      debugger
      const { details, id, name, type, ...formStateData } = formState.data;
      
      const attributeData = {
        id: id.value,
        name: name.value,
        rows: details?.rows.map((row : any) => {
          const newRow: Partial<CourierExpenses> = {
            expense_name: '',
            cost: 0,
          };

          row?.map((cell : any) => {
            if (cell.name === 'expense_name') {
              newRow.expense_name = cell.type.value as string;
            } 
            if (cell.name === 'cost') {
              newRow.cost = cell.type.value as number;
            } 
          })

          return newRow;

        }) || []
      };

      const response = await axiosInstance.put(`/courier-update/${attributeData.id}`, attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteCourier = createAsyncThunk(
  'courier/deleteCourier',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/courier-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const attributeSlice = createSlice({
  name: 'courier',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCouriers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCouriers.fulfilled, (state, action:  PayloadAction<PaginationObject<Courier>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchCouriers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllCouriers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllCouriers.fulfilled, (state, action:  PayloadAction<Courier[]>) => {
        state.couriers = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllCouriers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchCourier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCourier.fulfilled, (state, action:  PayloadAction<Courier>) => {
        state.courier = action.payload;
        state.loading = false;
      })
      .addCase(fetchCourier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createCourier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCourier.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createCourier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateCourier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCourier.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateCourier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteCourier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCourier.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteCourier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = attributeSlice.actions;

export default attributeSlice.reducer;
