import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../../../app/store';
import { RootState } from '../../../app/store';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../components/theme';
import './ThemeColorTab.css';

interface SidebarProps {}

const ThemeColorTab: React.FC<SidebarProps> = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.menu.loading);
  const error = useSelector((state: RootState) => state.menu.error);
  const location = useLocation();

  const { menuColor, setMenuColor, setTopNavColor, topNavColor, setBrandingTextColorHorizontalNavigation,
    setTextColor, setHoverBGColor, setHoverTextColor } = useTheme();

  const handleSideNavChange = (newTheme: string) => {
    if (newTheme === 'colorSilver' || newTheme === 'colorDefault') {
      setTextColor('colorBlack');
      if (newTheme === 'colorSilver') {
        setHoverBGColor('colorWhite')
        setHoverTextColor('colorBlack')
      } else {
        setHoverBGColor('colorBlack')
        setHoverTextColor('colorWhite')
      }
      
    } else {
      setTextColor('colorWhite');
      setHoverBGColor('colorBlack')
      setHoverTextColor('colorWhite')
    }
    setMenuColor(newTheme as any);
  };

  const handleTopNavChange = (newTheme: string) => {
    if (newTheme === 'colorSilver' || newTheme === 'colorDefault') {
      setBrandingTextColorHorizontalNavigation('colorBlack');
    } 
    setTopNavColor(newTheme as any);
   
  };

  return (
    <>
    <div className="mb-5">
      <p className="switcher-style-head mb-2">{t('Menu Color:')}</p>
      <span className='hidden bg-colorBlack text-colorWhite hover:bg-colorBlack hover:text-colorBlack'></span>
      <span className='hidden bg-colorWhite text-colorBlack hover:bg-colorWhite hover:text-colorWhite'></span>

      <div className="flex switcher-style space-x-3 rtl:space-x-reverse">
        <div className="ti-main-tooltip">
          
          <input
            className="hs-tooltip-toggle ti-form-radio light-coral-color-input color-input bg-colorLightCoral"
            type="radio"
            name="menu-colors"
            checked={menuColor === 'colorLightCoral'}
            onChange={() => handleSideNavChange('colorLightCoral')}
          />
        </div>

        <div className="ti-main-tooltip">
          <input
            className="hs-tooltip-toggle ti-form-radio dark-orange-color-input color-input bg-colorDarkOrange"
            type="radio"
            name="menu-colors"
            checked={menuColor === 'colorDarkOrange'}
            onChange={() => handleSideNavChange('colorDarkOrange')}
          />
        </div>

        <div className="ti-main-tooltip">
          <input
            className="hs-tooltip-toggle ti-form-radio dark-turquoise-color-input color-input bg-colorDarkTurquoise"
            type="radio"
            name="menu-colors"
            checked={menuColor === 'colorDarkTurquoise'}
            onChange={() => handleSideNavChange('colorDarkTurquoise')}
          />
        </div>

        <div className="ti-main-tooltip">
          <input
            className="hs-tooltip-toggle ti-form-radio royal-blue-color-input color-input bg-colorRoyalBlue"
            type="radio"
            name="menu-colors"
            checked={menuColor === 'colorRoyalBlue'}
            onChange={() => handleSideNavChange('colorRoyalBlue')}
          />
        </div>

        <div className="ti-main-tooltip">
          <input
            className="hs-tooltip-toggle ti-form-radio silver-color-input color-input bg-colorSilver"
            type="radio"
            name="menu-colors"
            checked={menuColor === 'colorSilver'}
            onChange={() => handleSideNavChange('colorSilver')}
          />
        </div>

        <div className="ti-main-tooltip">
          <input
            className="hs-tooltip-toggle ti-form-radio default-color-input color-input bg-colorWhite"
            type="radio"
            name="menu-colors"
            checked={menuColor === 'colorDefault'}
            onChange={() => handleSideNavChange('colorDefault')}
          />
        </div>
      </div>
    </div>

    <div className="mb-5">
      <p className="switcher-style-head mb-2">{t('Top Nav Color:')}</p>
      <div className="flex switcher-style space-x-3 rtl:space-x-reverse">

        <div className="ti-main-tooltip">
          <input
            className="hs-tooltip-toggle ti-form-radio silver-color-input color-input bg-colorSilver"
            type="radio"
            name="top-nav-colors"
            checked={topNavColor === 'colorSilver'}
            onChange={() => handleTopNavChange('colorSilver')}
          />
        </div>

        <div className="ti-main-tooltip">
          <input
            className="hs-tooltip-toggle ti-form-radio default-color-input color-input bg-colorDefault"
            type="radio"
            name="top-nav-colors"
            checked={topNavColor === 'colorDefault'}
            onChange={() => handleTopNavChange('colorDefault')}
          />
        </div>
      </div>
    </div>
    </>
  );
};

export default ThemeColorTab;
