// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { UnitGroup, UnitGroupState } from './unitGroupInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField } from '../../../components/componentInterface';

const initialState: UnitGroupState = {
  unitGroups: [],
  unitGroup: {
    name: '',
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};

// Async thunk for fetching buyers
export const fetchUnitGroups = createAsyncThunk(
  'unitGroup/fetchUnitGroups',
  async ({ page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE }: { page?: number; perPage?: number }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/unit-group-list?page=${page}&perPage=${perPage}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAllUnitGroups = createAsyncThunk(
  'unitGroup/fetchAllUnitGroups',
  async () => {
    
    try {
      const response = await axiosInstance.get(`/unit-group-list-all`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchUnitGroup = createAsyncThunk(
  'unitGroup/fetchUnitGroup',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/unit-group-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createUnitGroup = createAsyncThunk(
  'unitGroup/createUnitGroup',
  async (formState: Omit<UnitGroup, 'id'>, { rejectWithValue }) => {
    try {

      const { details, name,  } = formState.data;
      
      const attributeData = {
        name: name.value,
      };
      // debugger

      const response = await axiosInstance.post('/unit-group-save', attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateUnitGroup = createAsyncThunk(
  'unitGroup/updateUnitGroup',
  async (formState: Omit<UnitGroup, 'id'>, { rejectWithValue }) => {
    try {
      debugger
      const { details, id, name,  } = formState.data;
      
      const attributeData = {
        id: id.value,
        name: name.value,
      };
      debugger

      const response = await axiosInstance.put(`/unit-group-update/${attributeData.id}`, attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteUnitGroup = createAsyncThunk(
  'unitGroup/deleteUnitGroup',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/unit-group-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const unitGroupSlice = createSlice({
  name: 'unitGroup',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnitGroups.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUnitGroups.fulfilled, (state, action:  PayloadAction<PaginationObject<UnitGroup>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchUnitGroups.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllUnitGroups.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllUnitGroups.fulfilled, (state, action:  PayloadAction<UnitGroup[]>) => {
        state.unitGroups = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllUnitGroups.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchUnitGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUnitGroup.fulfilled, (state, action:  PayloadAction<UnitGroup>) => {
        state.unitGroup = action.payload;
        state.loading = false;
      })
      .addCase(fetchUnitGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createUnitGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUnitGroup.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createUnitGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateUnitGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUnitGroup.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateUnitGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteUnitGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUnitGroup.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteUnitGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = unitGroupSlice.actions;

export default unitGroupSlice.reducer;
