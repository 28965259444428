// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { BusinessAreaState, BusinessArea } from './businessAreaInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField, Option } from '../../../components/componentInterface';
import { checkIfBussinessUnitStore, getBussinessUnitData, saveBussinessUnitData } from '../../../app/idb/bussinessUnitOperations';

const initialState: BusinessAreaState = {
  businessAreas: [],
  businessArea: {
    code: '',
    company_id: 0,
    name: '',
    status: 'active',
    id: 0,
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};


export const fetchBusinessAreas = createAsyncThunk(
  'businessArea/fetchBusinessAreas',
    async (
      { page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE, search = '' }: { page?: number; perPage?: number; search?: string },
      { rejectWithValue }
    ) => {
      try {
        const response = await axiosInstance.get(`/business-area-list`, {
          params: {
            page,
            perPage,
            search,
          },
        });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || error.message);
      }
    }
  );
  


export const fetchAllBusinessArea = createAsyncThunk(
  'businessArea/fetchAllBusinessArea',
  async () => {
    
    try {

      const {exists, hasData} = await checkIfBussinessUnitStore();
      
      if (!hasData) {
        const response = await axiosInstance.get(`/business-area-list-all`);
        await saveBussinessUnitData(response.data);
        return response.data;
      } else {
        const data = await getBussinessUnitData();
        return data;
      }
      
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchBusinessArea = createAsyncThunk(
  'businessArea/fetchBusinessArea',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/business-area-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createBusinessArea = createAsyncThunk(
  'businessArea/createBusinessArea',
  async (formState: Omit<BusinessArea, 'id'>, { rejectWithValue }) => {
    try {

      const { name, description } = formState.data;
      
      const attributeData = {
        name: name.value,
        description: description.value,
      };
      // debugger

      const response = await axiosInstance.post('/business-area-save', attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateBusinessArea = createAsyncThunk(
  'businessArea/updateBusinessArea',
  async (formState: Omit<BusinessArea, 'id'>, { rejectWithValue }) => {
    try {
      debugger
      const {  id, name, description } = formState.data;
      
      const attributeData = {
        id: id.value,
        name: name.value,
        description: description.value,
      };
      debugger

      const response = await axiosInstance.put(`/business-area-update/${attributeData.id}`, attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteBusinessArea = createAsyncThunk(
  'businessArea/deleteBusinessArea',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/business-area-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const voucherTypeSlice = createSlice({
  name: 'voucherType',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessAreas.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBusinessAreas.fulfilled, (state, action:  PayloadAction<PaginationObject<BusinessArea>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchBusinessAreas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllBusinessArea.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllBusinessArea.fulfilled, (state, action:  PayloadAction<BusinessArea[]>) => {
        state.businessAreas = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllBusinessArea.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchBusinessArea.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBusinessArea.fulfilled, (state, action:  PayloadAction<BusinessArea>) => {
        state.businessArea = action.payload;
        state.loading = false;
      })
      .addCase(fetchBusinessArea.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createBusinessArea.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createBusinessArea.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createBusinessArea.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateBusinessArea.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBusinessArea.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateBusinessArea.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteBusinessArea.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteBusinessArea.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteBusinessArea.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = voucherTypeSlice.actions;

export default voucherTypeSlice.reducer;
