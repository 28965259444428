// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { Company, CompanyState } from './companyInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField, Option } from '../../../components/componentInterface';

const initialState: CompanyState = {
  companies: [],
  company: {
    code: '',
    country: '',
    name: '',
    status: 'Active',
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};



// Async thunk for fetching buyers
export const fetchCompanies = createAsyncThunk(
  'company/fetchCompanies',
  async (
    { page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE, search = '' }: { page?: number; perPage?: number; search?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(`/company-list`, {
        params: {
          page,
          perPage,
          search,
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);


export const fetchAllCompanies = createAsyncThunk(
  'company/fetchAllCompanies',
  async () => {

    try {
      const response = await axiosInstance.get(`/company-list-all`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);


export const fetchCompany = createAsyncThunk(
  'company/fetchCompany',
  async ({ id }: { id: string }, { rejectWithValue }) => {

    try {
      const response = await axiosInstance.get(`/company-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createCompany = createAsyncThunk(
  'company/createCompany',
  async (formState: Omit<Company, 'id'>, { rejectWithValue }) => {
    try {
      debugger
      const { parent_account_id, account_name, description, order,
        is_transaction, is_exchange, is_reference, is_cash, is_bank, is_active } = formState.data;

      const attributeData = {
        parent_account_id: parent_account_id.selected.value,
        account_name: account_name.value,
        description: description.value,
        order: order.value,
        is_transaction: is_transaction.value ? 1 : 0,
        is_exchange: is_exchange.value ? 1 : 0,
        is_reference: is_reference.value ? 1 : 0,
        is_cash: is_cash.value ? 1 : 0,
        is_bank: is_bank.value ? 1 : 0,
        is_active: is_active.value ? 1 : 0,
      };
      // debugger

      const response = await axiosInstance.post('/company-save', attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateCompany = createAsyncThunk(
  'company/updateCompany',
  async (formState: Omit<Company, 'id'>, { rejectWithValue }) => {
    try {
      debugger
      const { id, parent_account_id, account_name, description, order,
        is_transaction, is_exchange, is_reference, is_cash, is_bank, is_active } = formState.data;

      const attributeData = {
        id: id.value,
        parent_account_id: parent_account_id.selected.value,
        account_name: account_name.value,
        description: description.value,
        order: order.value,
        is_transaction: is_transaction.value ? 1 : 0,
        is_exchange: is_exchange.value ? 1 : 0,
        is_reference: is_reference.value ? 1 : 0,
        is_cash: is_cash.value ? 1 : 0,
        is_bank: is_bank.value ? 1 : 0,
        is_active: is_active.value ? 1 : 0,
      };
      debugger

      const response = await axiosInstance.put(`/company-update/${attributeData.id}`, attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteCompany = createAsyncThunk(
  'company/deleteCompany',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/company-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const chartOfAccountSlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCompanies.fulfilled, (state, action: PayloadAction<PaginationObject<Company>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllCompanies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllCompanies.fulfilled, (state, action: PayloadAction<Company[]>) => {
        state.companies = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllCompanies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchCompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCompany.fulfilled, (state, action: PayloadAction<Company>) => {
        state.company = action.payload;
        state.loading = false;
      })
      .addCase(fetchCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createCompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCompany.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateCompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCompany.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteCompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCompany.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = chartOfAccountSlice.actions;

export default chartOfAccountSlice.reducer;
