// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { CostCenter, CostCenterState } from './costcenterInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField, Option } from '../../../components/componentInterface';
import { checkIfCostCenterStore, getCostCenterData, saveCostCenterData } from '../../../app/idb/costCenterOperations';

const initialState: CostCenterState = {
  costCenters: [],
  costCenter: {
    name: '',
    actualCost: 0,
    budget: 0,
    status: 'Active',
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};


export const fetchCostCenters = createAsyncThunk(
'costCenter/fetchCostCenters',
  async (
    { page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE, search = '' }: { page?: number; perPage?: number; search?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(`/costcenter-list`, {
        params: {
          page,
          perPage,
          search,
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);


export const fetchAllCostCenters = createAsyncThunk(
  'costCenter/fetchAllCostCenters',
  async () => {
    
    try {
      const {exists, hasData} = await checkIfCostCenterStore();
      
      if (!hasData) {
        const response = await axiosInstance.get(`/costcenter-list-all`);
        await saveCostCenterData(response.data);
        return response.data;
      } else {
        const data = await getCostCenterData();
        return data;
      }

    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchCostCenter = createAsyncThunk(
  'costCenter/fetchCostCenter',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/costcenter-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createCostCenter = createAsyncThunk(
  'costCenter/createCostCenter',
  async (formState: Omit<CostCenter, 'id'>, { rejectWithValue }) => {
    try {

      const { name, department_id, budget, actual_cost, description, parent_cost_center_id } = formState.data;
      debugger
      const attributeData = {
        name: name.value,
        department_id: department_id?.selected?.value,
        budget: budget.value,
        actual_cost: actual_cost.value,
        description: description.value,
        parent_cost_center_id: parent_cost_center_id.selected.value,
      };
      // debugger

      const response = await axiosInstance.post('/costcenter-save', attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateCostCenter = createAsyncThunk(
  'costCenter/updateCostCenter',
  async (formState: Omit<CostCenter, 'id'>, { rejectWithValue }) => {
    try {
      const { id, name, department_id, budget, actual_cost, description, parent_cost_center_id } = formState.data;
      
      const attributeData = {
        id: id.value,
        name: name.value,
        department_id: department_id?.selected?.value,
        budget: budget.value,
        actual_cost: actual_cost.value,
        description: description.value,
        parent_cost_center_id: parent_cost_center_id.selected.value,
      };

      const response = await axiosInstance.put(`/costcenter-update/${attributeData.id}`, attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteCostCenter = createAsyncThunk(
  'costCenter/deleteCostCenter',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/costcenter-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const costCenterSlice = createSlice({
  name: 'costCenter',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCostCenters.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCostCenters.fulfilled, (state, action:  PayloadAction<PaginationObject<CostCenter>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchCostCenters.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllCostCenters.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllCostCenters.fulfilled, (state, action:  PayloadAction<CostCenter[]>) => {
        state.costCenters = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllCostCenters.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchCostCenter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCostCenter.fulfilled, (state, action:  PayloadAction<CostCenter>) => {
        state.costCenter = action.payload;
        state.loading = false;
      })
      .addCase(fetchCostCenter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createCostCenter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCostCenter.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createCostCenter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateCostCenter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCostCenter.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateCostCenter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteCostCenter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCostCenter.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteCostCenter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = costCenterSlice.actions;

export default costCenterSlice.reducer;
