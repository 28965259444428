import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBell as faSolidBell,
  faUser,
  faCog,
  faQuestionCircle,
  faSignOutAlt,
  faMoon,
  faExpand,
  faCompress,
  faSun,
  faListAlt,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import profile_img from "../../img/profile-img.jpg";
import "./Navbar.css";
import { logout } from "../../modules/auth/authSlice";
import SettingsIcon from "../../components/SettingsIcon";
import { useTheme } from "../../components/theme";
import SidebarBranding from "./SidebarBranding";
import pusherDataSyncService from "../../app/pusherDataSyncService";
import { BUSSINESS_UNIT_UPDATED_EVENT, CHART_OF_ACCOUNT_UPDATED_EVENT, COST_CENTER_UPDATED_EVENT, CURRENCY_UPDATED_EVENT, VOUCHER_TYPE_UPDATED_EVENT } from "../../app/URL/student-information";
import axiosInstance from "../../api/axiosInstance";
import { saveCostCenterData } from "../../app/idb/costCenterOperations";
import { saveChartOfAccountData } from "../../app/idb/coaOperations";
import { saveBussinessUnitData } from "../../app/idb/bussinessUnitOperations";
import { saveVoucherTypeData } from "../../app/idb/voucherTypeOperations";
import { saveCurrencyData } from "../../app/idb/currencyOperations";

interface TopNavProps {
  onToggleSidebar: () => void;
  onToggleMobileSidebar: () => void;
  settingClick: () => void;
  moduleName: string;
  isSideBarOpen: boolean;
  isClosedSidebarOpen: boolean;
}

const Navbar: React.FC<TopNavProps> = ({
  onToggleSidebar,
  onToggleMobileSidebar,
  isSideBarOpen,
  settingClick,
  isClosedSidebarOpen,
}) => {
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());

    navigate("/login");
  };

  const handleMenuBarClick = () => {
    onToggleSidebar();
  };

  const handleMobileMenuBarClick = () => {
    onToggleMobileSidebar();
  };

  const handleSettingsClick = () => {
    settingClick();
    // setIsRightSidebarOpen(!isRightSidebarOpen)
  };

  const [showNotifications, setShowNotifications] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const profileRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        profileRef.current &&
        !profileRef.current.contains(event.target as Node)
      ) {
        setShowProfile(false);
      }
    };

    if (showProfile) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showProfile]);

  const toggleProfile = () => setShowProfile(!showProfile);

  const [fullScreen, setFullScreen] = useState(false);

  const toggleFullScreen = () => {
    const elem = document.documentElement;

    if (!document.fullscreenElement) {
      elem.requestFullscreen().then(() => setFullScreen(true));
    } else {
      document.exitFullscreen().then(() => setFullScreen(false));
    }
  };

  const handleFullscreenChange = () => {
    setFullScreen(!!document.fullscreenElement);
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const { theme, toggleTheme, setThemeColor, topNavColor, navigationStyle, moduleName, isMobileView, isOnline } =
    useTheme();

  const homePage = () => {

    navigate('/dashboard/index');
  };

  useEffect(() => {


    const handleCurrencyUpdate = async (data: any) => {
      await saveCurrencyData(data.updateData)
    };

    const handleVoucherTypesUpdate = async (data: any) => {
      await saveVoucherTypeData(data.updateData)
    };
    
    const handleBussinessUnitUpdate = async (data: any) => {
      await saveBussinessUnitData(data.updateData)
    };

    const handleChartOfAccountUpdate = async (data: any) => {
      await saveChartOfAccountData(data.updateData)
    };

    const handleCostCenterUpdate = async (data: any) => {
      await saveCostCenterData(data.updateData)
    };

    pusherDataSyncService.subscribe(BUSSINESS_UNIT_UPDATED_EVENT, handleBussinessUnitUpdate);
    pusherDataSyncService.subscribe(CURRENCY_UPDATED_EVENT, handleCurrencyUpdate);
    pusherDataSyncService.subscribe(CHART_OF_ACCOUNT_UPDATED_EVENT, handleChartOfAccountUpdate);
    pusherDataSyncService.subscribe(VOUCHER_TYPE_UPDATED_EVENT, handleVoucherTypesUpdate);
    pusherDataSyncService.subscribe(COST_CENTER_UPDATED_EVENT, handleCostCenterUpdate);

    return () => {
      pusherDataSyncService.unsubscribe(BUSSINESS_UNIT_UPDATED_EVENT, handleBussinessUnitUpdate);
      pusherDataSyncService.unsubscribe(CURRENCY_UPDATED_EVENT, handleCurrencyUpdate);
      pusherDataSyncService.unsubscribe(CHART_OF_ACCOUNT_UPDATED_EVENT, handleChartOfAccountUpdate);
      pusherDataSyncService.unsubscribe(VOUCHER_TYPE_UPDATED_EVENT, handleVoucherTypesUpdate);
      pusherDataSyncService.unsubscribe(COST_CENTER_UPDATED_EVENT, handleCostCenterUpdate);
    };
  }, []);

  useEffect(() => {

    const fetchLedgerData = async () => {
      try {
        // Retrieve last timestamp from localStorage, defaulting to the current time in seconds if not found
        const lastTimestamp = localStorage.getItem('lastTimestamp') || null;
    
        // Make the API call with the timestamp as a query parameter
        const response = await axiosInstance.get(`/last-update?time_stamp=${lastTimestamp}`);
    
        // Handle the response data (e.g., ledger updates)
        console.log('Ledger data:', response.data.last_updated);
    
        // If the response includes a new timestamp, update it in localStorage
        if (response.data.current_timestamp) {
          localStorage.setItem('lastTimestamp', response.data.current_timestamp);
        }
      } catch (error) {
        console.error("Error fetching ledger data:", error);
      }
    };

    fetchLedgerData()

  }, []);

  return (
    <nav
      className={`bg-${topNavColor} dark:bg-colorBlack fixed-top ${navigationStyle === "Horizontal"
        ? "sidespan-open-full"
        : isSideBarOpen
          ? "sidespan-open"
          : isClosedSidebarOpen
            ? "sidespan-open"
            : "sidespan-close"
        } ${isMobileView ? 'dashboard' : moduleName.toLowerCase()}`}
    >
      <div className="container">
        <div className="flex justify-between items-center">
          {navigationStyle === "Vertical" && (
            <div className="flex items-center">
              {
                isMobileView ?
                  <FontAwesomeIcon
                    onClick={handleMobileMenuBarClick}
                    icon={faBars}
                    className={`cursor-pointer dark:text-white text-gray-600 ml-2 
                  ${moduleName.toLowerCase() === "dashboard" || isClosedSidebarOpen
                        ? "hidden"
                        : ""
                      }`}
                  />
                  :
                  <FontAwesomeIcon
                    onClick={handleMenuBarClick}
                    icon={faBars}
                    className={`cursor-pointer dark:text-white text-gray-600 ml-2 
                  ${moduleName.toLowerCase() === "dashboard" || isClosedSidebarOpen
                        ? "hidden"
                        : ""
                      }`}
                  />
              }

            </div>
          )}

          {navigationStyle === "Horizontal" && (
            <SidebarBranding
              isClosedSidebarOpen={isMobileView ? !isMobileView : isClosedSidebarOpen}
              isOpen={isSideBarOpen}
            />
          )}

          {/* Navigation Links */}
          <div className="flex items-center ml-auto">
            {
              isMobileView && moduleName.toLowerCase() !== "dashboard" &&
              <FontAwesomeIcon
                onClick={homePage}
                icon={faHome}
                className={`cursor-pointer dark:text-white text-gray-600 ml-2 mr-2 
                    }`}
              />
            }

            <span
              className={`h-2 w-2 rounded-full mr-2 ${isOnline ? 'bg-green-500' : 'bg-gray-400'}`}
            ></span>

            <span className="mr-2 dark:text-white text-sm sm:text-base md:text-lg lg:text-xl">
              <FontAwesomeIcon icon={faListAlt} className="mr-2" />
              {moduleName}
            </span>

            <FontAwesomeIcon
              aria-label="anchor"
              onClick={toggleTheme}
              icon={theme === "dark" ? faSun : faMoon}
              className="dark:text-white text-gray-600 mr-5 cursor-pointer"
            />
            <FontAwesomeIcon
              aria-label="anchor"
              icon={faSolidBell}
              className="dark:text-white text-gray-600 mr-5 cursor-pointer"
            />
            <FontAwesomeIcon
              aria-label="anchor"
              onClick={toggleFullScreen}
              icon={fullScreen ? faCompress : faExpand}
              className="dark:text-white text-gray-600 mr-5 cursor-pointer"
            />
            {/* Dropdown Menu */}
            <div className="relative" ref={profileRef}>
              <a
                href="#"
                className="nav-link nav-profile flex items-center"
                aria-label="Profile"
                aria-haspopup="true"
                onClick={toggleProfile}
              >
                <img
                  src={profile_img}
                  alt="Profile"
                  className="rounded-full h-10 w-10 object-cover"
                />
                <span className="ml-2 hidden md:block text-colorBlack dark:text-colorWhite">
                  K. Anderson
                </span>
              </a>
              {showProfile && (
                <ul className="dropdown-menu profile dropdown-menu-end dropdown-menu-arrow absolute bg-colorWhite dark:bg-colorBlack rounded-md mt-2 w-80 shadow-clWhite dark:shadow-black">
                  <li className="dropdown-header">
                    <h6 className="font-semibold text-colorBlack dark:text-colorWhite">
                      Kevin Anderson
                    </h6>
                    <span className="text-xs text-gray-600">Web Designer</span>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  {/* Profile Options */}
                  <li className="px-4 py-3">
                    <a
                      href="#"
                      className="flex items-center text-sm text-colorBlack dark:text-colorWhite hover:text-gray-900"
                    >
                      <FontAwesomeIcon
                        icon={faUser}
                        className="text-gray-600"
                      />
                      <span className="ml-2">My Profile</span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="px-4 py-3">
                    <a
                      href="#"
                      className="flex items-center text-sm text-colorBlack dark:text-colorWhite hover:text-gray-900"
                    >
                      <FontAwesomeIcon icon={faCog} className="text-gray-600" />
                      <span className="ml-2">Account Settings</span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="px-4 py-3">
                    <a
                      href="#"
                      className="flex items-center text-sm text-colorBlack dark:text-colorWhite hover:text-gray-900"
                    >
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        className="text-gray-600"
                      />
                      <span className="ml-2">Need Help?</span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  {/* Sign Out */}
                  <li className="px-4 py-3">
                    <a
                      href="#"
                      onClick={handleLogout}
                      className="flex items-center text-sm text-colorBlack dark:text-colorWhite hover:text-gray-900"
                    >
                      <FontAwesomeIcon
                        icon={faSignOutAlt}
                        className="text-gray-600"
                      />
                      <span className="ml-2">Sign Out</span>
                    </a>
                  </li>
                </ul>
              )}
            </div>
            <SettingsIcon
              onClick={handleSettingsClick}
              className="ml-2 mr-5 cursor-pointer"
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
