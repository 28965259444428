// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { VoucherTypeState, VoucherType } from './voucherTypeInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField, Option } from '../../../components/componentInterface';
import { checkIfVoucherTypeStore, getVoucherTypeData, saveVoucherTypeData } from '../../../app/idb/voucherTypeOperations';

const initialState: VoucherTypeState = {
  voucherTypes: [],
  voucherType: {
    alias: '',
    is_default: false,
    nature: '1',
    type_name: '',
    v_type: '',
    description: '',
    company_id: 0,
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};


export const fetchVoucherTypes = createAsyncThunk(
  'voucherType/fetchVoucherTypes',
    async (
      { page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE, search = '' }: { page?: number; perPage?: number; search?: string },
      { rejectWithValue }
    ) => {
      try {
        const response = await axiosInstance.get(`/voucher-type-list`, {
          params: {
            page,
            perPage,
            search,
          },
        });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || error.message);
      }
    }
  );
  


export const fetchAllVoucherTypes = createAsyncThunk(
  'voucherType/fetchAllVoucherTypes',
  async () => {
    
    try {
   
      const {exists, hasData} = await checkIfVoucherTypeStore();
      
      if (!hasData) {
        const response = await axiosInstance.get(`/voucher-type-list-all`);
        await saveVoucherTypeData(response.data);
        return response.data;
      } else {
        const data = await getVoucherTypeData();
        return data;
      }

    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchVoucherType = createAsyncThunk(
  'voucherType/fetchVoucherType',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/voucher-type-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createVoucherType = createAsyncThunk(
  'voucherType/createVoucherType',
  async (formState: Omit<VoucherType, 'id'>, { rejectWithValue }) => {
    try {

      const { name, description } = formState.data;
      
      const attributeData = {
        name: name.value,
        description: description.value,
      };
      // debugger

      const response = await axiosInstance.post('/voucher-type-save', attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateVoucherType = createAsyncThunk(
  'voucherType/updateVoucherType',
  async (formState: Omit<VoucherType, 'id'>, { rejectWithValue }) => {
    try {
      debugger
      const {  id, name, description } = formState.data;
      
      const attributeData = {
        id: id.value,
        name: name.value,
        description: description.value,
      };
      debugger

      const response = await axiosInstance.put(`/voucher-type-update/${attributeData.id}`, attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteVoucherType = createAsyncThunk(
  'voucherType/deleteVoucherType',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/voucher-type-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const voucherTypeSlice = createSlice({
  name: 'voucherType',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVoucherTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVoucherTypes.fulfilled, (state, action:  PayloadAction<PaginationObject<VoucherType>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchVoucherTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllVoucherTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllVoucherTypes.fulfilled, (state, action:  PayloadAction<VoucherType[]>) => {
        state.voucherTypes = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllVoucherTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchVoucherType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVoucherType.fulfilled, (state, action:  PayloadAction<VoucherType>) => {
        state.voucherType = action.payload;
        state.loading = false;
      })
      .addCase(fetchVoucherType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createVoucherType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createVoucherType.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createVoucherType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateVoucherType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateVoucherType.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateVoucherType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteVoucherType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteVoucherType.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteVoucherType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = voucherTypeSlice.actions;

export default voucherTypeSlice.reducer;
