// src/services/WebSocketService.ts

type MessageCallback = (data: any) => void;

class WebSocketService {
  public socket: WebSocket | null = null;
  public callbacks: { [key: string]: MessageCallback | undefined } = {};

  // Connect to the WebSocket server
  connect(url: string): void {
    this.socket = new WebSocket(url);

    this.socket.onopen = () => {
      console.log('WebSocket connection established');
    };

    this.socket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    this.socket.onmessage = (event) => {
      this.handleMessage(event.data);
    };
  }

  // Send a message through the WebSocket
  sendMessage(message: string): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.error('WebSocket is not open');
    }
  }

  onMessage(callback: (data: string) => void) {
    if (this.socket) {
        this.socket.onmessage = (event) => {
            callback(event.data);
        };
    }
}

  // Handle incoming messages
  private handleMessage(data: string): void {
    try {
      const parsedData = JSON.parse(data);
      if (this.callbacks['message']) {
        this.callbacks['message'](parsedData);
      }
    } catch (error) {
      console.error('Failed to parse WebSocket message:', error);
    }
  }
}

const webSocketService = new WebSocketService();
export default webSocketService;
