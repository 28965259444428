// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { VoucherState, VoucherMaster } from './voucherInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField, Option } from '../../../components/componentInterface';
import { formatDate } from '../../../app/utils';

const initialState: VoucherState = {
  vouchers: [],
  voucher: {
    details: [],
    total_amount: 0,
    voucher_number: '',
    business_area_id: -1,
    voucher_type_id: 0,
    narration: '',
    voucher_date: new Date(),
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};


export const fetchVouchers = createAsyncThunk(
  'voucher/fetchVouchers',
    async (
      { page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE, search = '' }: { page?: number; perPage?: number; search?: string },
      { rejectWithValue }
    ) => {
      try {
        const response = await axiosInstance.get(`/voucher-list`, {
          params: {
            page,
            perPage,
            search,
          },
        });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || error.message);
      }
    }
  );
  

export const fetchVoucher = createAsyncThunk(
  'voucher/fetchVoucher',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/voucher-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createVoucher2 = createAsyncThunk(
  'voucher/createVoucher',
  async (formState: VoucherMaster, { rejectWithValue }) => {
    try {

      const { details, business_area_id, voucher_date, voucher_type_id, narration  } = formState;

      debugger
      const { name, description } = formState.data;
  
      const response = await axiosInstance.post('/voucher-save', {
        details: details,
        voucher_date: formatDate(voucher_date as any, 'db_format'),
        voucher_type_id: voucher_type_id,
        business_area_id: business_area_id,
        narration: narration
      });
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const createVoucher = createAsyncThunk(
  'voucher/createVoucher',
  async (formState: VoucherMaster, { rejectWithValue }) => {
    try {
      const { details, business_area_id, voucher_date, voucher_type_id, narration, voucher_attachments } = formState;

      // Create a FormData object
      const formData = new FormData();

      // Append non-file fields
      formData.append('voucher_date', formatDate(voucher_date as any, 'db_format'));
      formData.append('voucher_type_id', voucher_type_id.toString());
      formData.append('business_area_id', business_area_id.toString());
      formData.append('narration', narration || '');
      formData.append('details', JSON.stringify(details));
      // Append details array as JSON string

      // Append attachments if any
      if (voucher_attachments) {
        voucher_attachments.forEach((file: File, index: number) => {
          formData.append(`attachments[${index}]`, file);
        });
      }

      const response = await axiosInstance.post('/voucher-save', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Required for file upload
        },
      });

      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateVoucher2 = createAsyncThunk(
  'voucher/updateVoucher',
  async (formState: Omit<VoucherMaster, 'id'>, { rejectWithValue }) => {
    try {
      debugger
      const {  id, name, description } = formState.data;
      
      const attributeData = {
        id: id.value,
        name: name.value,
        description: description.value,
      };
      debugger

      const response = await axiosInstance.put(`/voucher-type-update/${attributeData.id}`, attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);


export const updateVoucher = createAsyncThunk(
  'voucher/updateVoucher',
  async (formState: VoucherMaster, { rejectWithValue }) => {
    try {
      const { id, details, business_area_id, voucher_date, voucher_type_id, narration, voucher_attachments } = formState;
// debugger
      // Create a FormData object
      const formData = new FormData();

      // Append non-file fields
      formData.append('voucher_date', formatDate(voucher_date as any, 'db_format'));
      formData.append('voucher_type_id', voucher_type_id.toString());
      formData.append('business_area_id', business_area_id.toString());
      formData.append('narration', narration || '');
      formData.append('details', JSON.stringify(details));
      // Append details array as JSON string

      // Append attachments if any
      if (voucher_attachments) {
        voucher_attachments.forEach((file: File, index: number) => {
          formData.append(`attachments[${index}]`, file);
        });
      }

      const response = await axiosInstance.post(`/voucher-update/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Required for file upload
        },
      });

      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteVoucher = createAsyncThunk(
  'voucher/deleteVoucher',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/voucher-type-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const voucherSlice = createSlice({
  name: 'voucher',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVouchers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVouchers.fulfilled, (state, action:  PayloadAction<PaginationObject<VoucherMaster>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchVouchers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchVoucher.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVoucher.fulfilled, (state, action:  PayloadAction<VoucherMaster>) => {
        state.voucher = action.payload;
        state.loading = false;
      })
      .addCase(fetchVoucher.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createVoucher.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createVoucher.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createVoucher.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateVoucher.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateVoucher.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateVoucher.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteVoucher.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteVoucher.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteVoucher.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = voucherSlice.actions;

export default voucherSlice.reducer;
