// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { Customer, CustomerState } from './customerInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField } from '../../../components/componentInterface';
import { Order, OrderItem } from '../../ecommerce/orderList/orderListInterface';

const initialState: CustomerState = {
  orderItems: null,
  orders: null,
  customers: null,
  customer: {
    name: '',
    total_amount: 0,
    data: {},
    orders: null,
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};

// Async thunk for fetching buyers
export const fetchAllShipmentPendingCustomers = createAsyncThunk(
  'customer/fetchAllShipmentPendingCustomers',
  async () => {
    
    try {
      const response = await axiosInstance.get(`/pending-customer-list-all`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAllShipmentPendingCustomersOrders = createAsyncThunk(
  'customer/fetchAllShipmentPendingCustomersOrders',
  async (customer_id: number) => {
    
    try {
      const response = await axiosInstance.get(`/pending-customer-list-all?customer_id=${customer_id}`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAllShipmentPendingCustomersOrdersItems = createAsyncThunk(
  'customer/fetchAllShipmentPendingCustomersOrdersItems',
  async (order_id: number) => {
    
    try {
      const response = await axiosInstance.get(`/pending-customer-list-all?order_id=${order_id}`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);


const attributeSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllShipmentPendingCustomers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllShipmentPendingCustomers.fulfilled, (state, action:  PayloadAction<Customer[]>) => {
        state.customers = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllShipmentPendingCustomers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllShipmentPendingCustomersOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllShipmentPendingCustomersOrders.fulfilled, (state, action:  PayloadAction<Order[]>) => {
        state.orders = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllShipmentPendingCustomersOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllShipmentPendingCustomersOrdersItems.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllShipmentPendingCustomersOrdersItems.fulfilled, (state, action:  PayloadAction<OrderItem[]>) => {
        state.orderItems = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllShipmentPendingCustomersOrdersItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = attributeSlice.actions;

export default attributeSlice.reducer;
