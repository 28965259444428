import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../app/store'; // Adjust path as per your project structure
import { fetchMenuItems, selectMenuItems,  } from '../../modules/auth/menuSlice';
import { loginUser } from '../../modules/auth/authSlice';
import { AppDispatch } from '../../app/store';
import { Link, useLocation } from 'react-router-dom';
import './RightSidebar.css';
import SkeletonLoader from '../../components/Skeleton';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';
import ToastrService from '../../app/toastrService';
import CustomTab from '../../components/CustomTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import ThemeColorTab from './RightSidebar/ThemeColorTab';
import ThemeStyleTab from './RightSidebar/ThemeStyleTab';
import { useTheme } from '../../components/theme';

interface SidebarProps {
  isOpen: boolean;
  onClickClose: () => void;
}


const RightSidebar: React.FC<SidebarProps> = ({ isOpen, onClickClose }) => {
  const { t, i18n } = useTranslation();

  const {isMobileView} = useTheme()

  const storedLanguage = localStorage.getItem('i18nextLng');
  const initialLanguage = storedLanguage || 'en';

  const dispatch: AppDispatch = useDispatch();
  const name = useState("");
  const menuItems = useSelector((state: RootState) => selectMenuItems(state));
  const loading = useSelector((state: RootState) => state.menu.loading);
  const error = useSelector((state: RootState) => state.menu.error);
  const location = useLocation();

  const changeLanguage = (lng: string, text: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
    ToastrService.success("Language Changed to " + text);
  };

  const tabs = [
    { label: t('Theme Style'), content: <ThemeStyleTab /> },
    { label: t('Theme Colors'), content: <ThemeColorTab /> },
  ];


  return (
    <aside id="right-sidebar" className={`z-999999 ${isMobileView ? 'right-sidebar-mobile' : 'right-sidebar'} ${isOpen ? 'open' : 'closed'} text-colorBlack 
    dark:text-colorWhite bg-colorWhite dark:bg-colorBlack`}>

    <FontAwesomeIcon icon={faTimesCircle} size='2x' onClick={onClickClose}
    className='colorBlack dark:colorWhite cursor-pointer float-right' />


      <CustomTab tabs={tabs} />

      {/* <h2 className="text-lg ">Language Setting</h2>
      <Button
        type='button'
        text={'English'}
        children={<></>}
        onClick={() => changeLanguage('en', 'English')}
        className={`bg-green-500 hover:bg-green-700 text-white mr-2 ${initialLanguage === 'en' ? 'bg-green-700' : ''}`}
      />
      <Button
        type='button'
        text={'Bangla'}
        children={<></>}
        onClick={() => changeLanguage('bn', 'Bangla')}
        className={`bg-green-500 hover:bg-green-700 text-white mr-2 ${initialLanguage === 'bn' ? 'bg-green-700' : ''}`}
      /> */}

    </aside>
  );
};

export default RightSidebar;
