// pusherService.ts
import Pusher, { Channel } from 'pusher-js';

// Define the structure of the listener objects
interface EventListener {
  eventName: string;
  callback: (data: any) => void;
}

class PusherDataSyncService {
  private pusher: Pusher;
  private channel: Channel;
  private listeners: EventListener[] = [];

  constructor() {
    const auth_unique_id = localStorage.getItem('auth_unique_id');
    this.pusher = new Pusher('f1b302688a0ddc032daa', {
      cluster: 'ap2',
    });
    this.channel = this.pusher.subscribe('user-updates.'+auth_unique_id);
  }

  /**
   * Subscribe to an event and register a callback
   * @param eventName - Name of the event to subscribe to
   * @param callback - Callback function that is triggered when the event is received
   */
  public subscribe(eventName: string, callback: (data: any) => void): void {
    this.channel.bind(eventName, callback);
    this.listeners.push({ eventName, callback });
  }

  /**
   * Unsubscribe from an event
   * @param eventName - Name of the event to unsubscribe from
   * @param callback - Callback function to remove from the event
   */
  public unsubscribe(eventName: string, callback: (data: any) => void): void {
    this.channel.unbind(eventName, callback);
    this.listeners = this.listeners.filter(
      listener => listener.eventName !== eventName || listener.callback !== callback
    );
  }

  /**
   * Clean up all listeners
   */
  public cleanup(): void {
    this.listeners.forEach(({ eventName, callback }) => {
      this.channel.unbind(eventName, callback);
    });
    this.channel.unsubscribe();
    this.listeners = [];
  }
}

export default new PusherDataSyncService();
