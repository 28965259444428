// src/api/cancelTokenManager.ts
import axios, { CancelTokenSource } from 'axios';

let cancelTokenSource: CancelTokenSource | null = null;

export const getCancelTokenSource = (): CancelTokenSource => {
  if (!cancelTokenSource) {
    cancelTokenSource = axios.CancelToken.source();
  }
  return cancelTokenSource;
};

export const resetCancelTokenSource = (): void => {
  cancelTokenSource?.cancel('Operation canceled by the user.');
  cancelTokenSource = axios.CancelToken.source();
};
