// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { Department, DepartmentState } from './departmentInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField, Option } from '../../../components/componentInterface';

const initialState: DepartmentState = {
  departments: [],
  department: {
    name: '',
    decription: '',
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};


export const fetchDepartments = createAsyncThunk(
  'department/fetchDepartments',
    async (
      { page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE, search = '' }: { page?: number; perPage?: number; search?: string },
      { rejectWithValue }
    ) => {
      try {
        const response = await axiosInstance.get(`/department-list`, {
          params: {
            page,
            perPage,
            search,
          },
        });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || error.message);
      }
    }
  );
  


export const fetchAllDepartments = createAsyncThunk(
  'department/fetchAllDepartments',
  async () => {
    
    try {
      const response = await axiosInstance.get(`/department-list-all`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchDepartment = createAsyncThunk(
  'department/fetchDepartment',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/department-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createDepartment = createAsyncThunk(
  'department/createDepartment',
  async (formState: Omit<Department, 'id'>, { rejectWithValue }) => {
    try {

      const { name, description } = formState.data;
      
      const attributeData = {
        name: name.value,
        description: description.value,
      };
      // debugger

      const response = await axiosInstance.post('/department-save', attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateDepartment = createAsyncThunk(
  'department/updateDepartment',
  async (formState: Omit<Department, 'id'>, { rejectWithValue }) => {
    try {
      debugger
      const {  id, name, description } = formState.data;
      
      const attributeData = {
        id: id.value,
        name: name.value,
        description: description.value,
      };
      debugger

      const response = await axiosInstance.put(`/department-update/${attributeData.id}`, attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteDepartment = createAsyncThunk(
  'department/deleteDepartment',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/department-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepartments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDepartments.fulfilled, (state, action:  PayloadAction<PaginationObject<Department>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchDepartments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllDepartments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllDepartments.fulfilled, (state, action:  PayloadAction<Department[]>) => {
        state.departments = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllDepartments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchDepartment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDepartment.fulfilled, (state, action:  PayloadAction<Department>) => {
        state.department = action.payload;
        state.loading = false;
      })
      .addCase(fetchDepartment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createDepartment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDepartment.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createDepartment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateDepartment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDepartment.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateDepartment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteDepartment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDepartment.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteDepartment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = departmentSlice.actions;

export default departmentSlice.reducer;
