// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { Attribute, AttributeState } from './attributeInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField, Option } from '../../../components/componentInterface';

const initialState: AttributeState = {
  attributes: [],
  attributeOptions: null,
  attribute: {
    name: '',
    type: '',
    display_order: 0,
    is_filterable: false,
    is_variation: false,
    is_visible: false,
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};

// Async thunk for fetching buyers
export const fetchAttributes = createAsyncThunk(
  'attribute/fetchAttributes',
  async ({ page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE }: { page?: number; perPage?: number }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/attribute-list?page=${page}&perPage=${perPage}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAllAttributeOptions = createAsyncThunk(
  'attribute/fetchAllAttributeOptions',
  async () => {
    
    try {
      const response = await axiosInstance.get(`/attribute-option-list-all`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAllAttributes = createAsyncThunk(
  'attribute/fetchAllAttributes',
  async () => {
    
    try {
      const response = await axiosInstance.get(`/attribute-list-all`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAttribute = createAsyncThunk(
  'attribute/fetchAttribute',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/attribute-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createAttribute = createAsyncThunk(
  'attribute/createAttribute',
  async (formState: Omit<Attribute, 'id'>, { rejectWithValue }) => {
    try {

      const { details, name, type, display_order, is_filterable, 
        is_visible, is_variation, 
         ...formStateData } = formState.data;
      
      const attributeData = {
        name: name.value,
        type: type.value,
        display_order: display_order.value,
        is_filterable: is_filterable.value,
        is_visible: is_visible.value,
        is_variation: is_variation.value,
        rows: details?.rows.map((row : any) => {
          const newRow: Partial<Attribute> = {
            option: ''
          };

          row?.map((cell : any) => {
            if (cell.name === 'option') {
              newRow.option = cell.type.value as string;
            } 
          })

          return newRow;

        }) || []
      };
      // debugger

      const response = await axiosInstance.post('/attribute-save', attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateAttribute = createAsyncThunk(
  'attribute/updateAttribute',
  async (formState: Omit<Attribute, 'id'>, { rejectWithValue }) => {
    try {
      debugger
      const { details, id, name, type, display_order, is_filterable, 
        is_visible, is_variation,
         ...formStateData } = formState.data;
      
      const attributeData = {
        id: id.value,
        name: name.value,
        type: type.value,
        display_order: display_order.value,
        is_filterable: is_filterable.value,
        is_visible: is_visible.value,
        is_variation: is_variation.value,
        rows: details?.rows.map((row : any) => {
          const newRow: Partial<Attribute> = {
            option: '',
            swatch_code: '',
            id: 0
          };

          row?.map((cell : any) => {
            if (cell.name === 'option') {
              newRow.option = cell.type.value as string;
            } 
            if (cell.name === 'swatch_code') {
              newRow.swatch_code = cell.type.value as string;
            } 
            if (cell.name === 'id') {
              newRow.id = cell.type.value as number;
            } 
          })

          return newRow;

        }) || []
      };
      debugger

      const response = await axiosInstance.put(`/attribute-update/${attributeData.id}`, attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteAttribute = createAsyncThunk(
  'attribute/deleteAttribute',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/attribute-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const attributeSlice = createSlice({
  name: 'attribute',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAttributes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAttributes.fulfilled, (state, action:  PayloadAction<PaginationObject<Attribute>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchAttributes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllAttributeOptions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllAttributeOptions.fulfilled, (state, action:  PayloadAction<Option[]>) => {
        state.attributeOptions = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllAttributeOptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllAttributes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllAttributes.fulfilled, (state, action:  PayloadAction<Attribute[]>) => {
        state.attributes = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllAttributes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAttribute.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAttribute.fulfilled, (state, action:  PayloadAction<Attribute>) => {
        state.attribute = action.payload;
        state.loading = false;
      })
      .addCase(fetchAttribute.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createAttribute.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAttribute.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createAttribute.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateAttribute.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAttribute.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateAttribute.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteAttribute.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAttribute.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteAttribute.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = attributeSlice.actions;

export default attributeSlice.reducer;
