import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/store"; // Adjust path as per your project structure
import {
  fetchMenuItems,
  selectMenuItems,
} from "../../modules/auth/menuSlice";
import { AppDispatch } from "../../app/store";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import SkeletonLoader from "../../components/Skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faCircleDot,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "../../components/theme";
import SidebarBranding from "./SidebarBranding";
import { toTitleCase } from "../../app/utils";

interface SidebarProps {
  isOpen: boolean;
  onChangeRoute: (segment: string) => void;
  onHandleMobileSideBarOpen: (condition: boolean) => void;
  moduleName: string;
}

const SidebarMobile: React.FC<SidebarProps> = ({
  isOpen,
  onChangeRoute,
  moduleName,
  onHandleMobileSideBarOpen,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const menuItems = useSelector((state: RootState) => selectMenuItems(state));
  const loading = useSelector((state: RootState) => state.menu.loading);
  const error = useSelector((state: RootState) => state.menu.error);
  const location = useLocation();
  const [firstSegment, setFirstSegment] = useState<string>("");
  const [isClosedSidebarOpen, setClosedSidebarOpen] = useState<boolean>(false);
  const [activeSubMenu, setActiveSubMenu] = useState<number | null>(null);
  const subMenuRefs = useRef<Record<number, HTMLUListElement | null>>({});

  const { menuColor, textColor, hoverBGColor, hoverTextColor, setModuleName, isMobileView } = useTheme();

  useEffect(() => {
    if (firstSegment !== "") {
      onChangeRoute(firstSegment);
      dispatch(fetchMenuItems({ firstSegment }));
      setModuleName(toTitleCase(firstSegment))
    }
  }, [firstSegment]);

  useEffect(() => {
    const segment = location.pathname.split("/")[1];
    console.log("menuItems", segment, menuItems);

    setFirstSegment(segment);
  }, [location]);

  const toggleSubMenu = (event: React.MouseEvent, id: number) => {
    event.preventDefault();
    setActiveSubMenu(activeSubMenu === id ? null : id);
  };

  const closeMenu = () => {
    onHandleMobileSideBarOpen(false);
  };


  return (
    <>
      {
        <div
          onClick={closeMenu}
          className={`fixed inset-0 bg-black z-40 transition-opacity duration-300 ${isOpen && moduleName !== 'dashboard' ? 'opacity-50' : 'opacity-0 pointer-events-none'
            }`}
        />
      }

      <aside
        className={`bg-${menuColor} dark:bg-colorBlack sidebar ${isMobileView ? 'mobile' : ''} ${isOpen ? "open" : "closed"} ${moduleName}`}
      >
        <SidebarBranding
          isOpen={isOpen}
          isClosedSidebarOpen={isClosedSidebarOpen}
        />

        <ul className="sidebar-nav mt-2 h-full overflow-y-auto">
          {loading && <SkeletonLoader />}
          {error && <p>Error: {error}</p>}
          {!loading &&
            menuItems.length > 0 &&
            menuItems.map((item) => (
              <li key={item.id} className="nav-item">
                {item.child ? (
                  <>
                    <a
                      className={`nav-link collapsed text-${textColor} dark:text-colorGray hover:bg-${hoverBGColor} hover:text-${hoverTextColor} dark:hover:bg-colorBlack1`}
                      href="#"
                      onClick={(event) => toggleSubMenu(event, item.id)}
                    >
                      <FontAwesomeIcon icon={faHome} className="mr-2" />
                      <span
                        className={`${isOpen || isClosedSidebarOpen ? "block" : "hidden"
                          }`}
                      >
                        {item.name}
                      </span>
                      <FontAwesomeIcon
                        icon={
                          activeSubMenu === item.id
                            ? faChevronDown
                            : faChevronRight
                        }
                        className="ml-2"
                      />
                    </a>
                    <ul
                      ref={(el) => (subMenuRefs.current[item.id] = el)}
                      className={`nav-content ${activeSubMenu === item.id || isClosedSidebarOpen
                        ? "expanded"
                        : "collapsed"
                        }`}
                      id={`submenu-${item.id}`}
                    >
                      {item.child.map((subItem) => (
                        <li key={subItem.id}>
                          <Link
                            to={subItem.route || "#"}
                            className={`hover:bg-${hoverBGColor} dark:hover:bg-colorBlack1 dark:text-colorGray ${subItem.active ? "active" : ""
                              } text-${textColor} hover:text-${hoverTextColor}`}
                          >
                            <FontAwesomeIcon
                              icon={faCircleDot}
                              className="mr-2"
                            />
                            <span
                              className={`${isOpen || isClosedSidebarOpen ? "block" : "hidden"
                                }`}
                            >
                              {subItem.name}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <Link
                    className={`nav-link hover:bg-${hoverBGColor} hover:text-${hoverTextColor} dark:hover:bg-colorBlack1 text-${textColor} dark:text-colorGray`}
                    to={item.route || "#"}
                  >
                    <i className={item.icon}></i>
                    <span>{item.name}</span>
                  </Link>
                )}
              </li>
            ))}
        </ul>
      </aside>
    </>
  );
};

export default SidebarMobile;
