import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '../../components/theme';
import logo from '../../img/logo.png'

interface SidebarBrandingProps {
    isOpen: boolean;
    isClosedSidebarOpen: boolean;
}

const SidebarBranding: React.FC<SidebarBrandingProps> = ({ isOpen, isClosedSidebarOpen }) => {
    const navigate = useNavigate();
    const {textColor, brandingTextColorHorizontalNavigation, navigationStyle, menuColor, topNavColor, moduleName } = useTheme()

    const homePage = () => {

        navigate('/dashboard/index');
    };

    return (
        <div className={`sidebar-branding  border-b border-customGray 
            ${navigationStyle === 'Horizontal' ? '' : ' ml-[-22px] mr-[-22px] w-full '}  
         z-99999 top-1 border-colorSilver 
         ${navigationStyle==='Horizontal'?'overflow-y-hidden not-fixed bg-' + topNavColor : 'overflow-y-scroll bg-'+menuColor}
          dark:bg-colorBlack sidebar`}>
            <div className="flex">
                <img onClick={homePage} src={logo} alt="Logo" className="h-5 ml-8  mt-1 cursor-pointer" />
                {isOpen && (
                    <span
                        onClick={homePage}
                        className={`text-${navigationStyle === 'Horizontal' ? brandingTextColorHorizontalNavigation : textColor} 
                        ml-3 text-xl font-bold cursor-pointer dark:text-colorWhite`}
                    >
                        {process.env.REACT_APP_APP_NAME}
                    </span>
                )}

                {isClosedSidebarOpen && (
                    <span
                        onClick={homePage}
                        className={`text-${navigationStyle === 'Horizontal' ? brandingTextColorHorizontalNavigation : textColor}
                         ml-3  text-xl font-bold cursor-pointer  dark:text-colorWhite`}
                    >
                        {process.env.REACT_APP_APP_NAME}
                    </span>
                )}
            </div>
        </div>
    );
};

export default SidebarBranding;
