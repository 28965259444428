// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { SiteSettings, SiteSettingsState } from './siteSettingsInterface';


const initialState: SiteSettingsState = {
  siteSettings: {
    contact_email: '',
    contact_phone: '',
    facebook_url: '',
    site_logo: '',
    site_name: '',
    twitter_url: '',
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};


export const fetchSiteSettings = createAsyncThunk(
  'siteSetting/fetchSiteSettings',
  async () => {
    
    try {
      const response = await axiosInstance.get(`/fetch-site-settings`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);


// Async thunk for creating a buyer
export const createSiteSetting = createAsyncThunk(
  'siteSetting/createSiteSetting',
  async (formState: Omit<SiteSettings, 'id'>, { rejectWithValue }) => {
    try {
      const { site_name, site_logo, contact_email, contact_phone, facebook_url, twitter_url } = formState.data;
      
      const formData = new FormData();

      const data = {
        site_name: site_name.value,
        contact_email: contact_email.value,
        contact_phone: contact_phone.value,
        site_logo: site_logo.value,
        facebook_url: facebook_url.value,
        twitter_url: twitter_url.value
      };
      debugger

      for (const key in data) {
        if (key === 'site_logo' && Array.isArray(data[key])) {
          (data[key] as File[]).forEach((file, index) => {
            formData.append(`site_logo`, file);
          });
        } else {
          formData.append(`${key}`, (data as any)[key]);
        }
        
      }

      const response = await axiosInstance.post('/save-site-settings', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data.message;

    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);


const attributeSlice = createSlice({
  name: 'siteSetting',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchSiteSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSiteSettings.fulfilled, (state, action:  PayloadAction<SiteSettings>) => {
        state.siteSettings = action.payload;
        state.loading = false;
      })
      .addCase(fetchSiteSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createSiteSetting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSiteSetting.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createSiteSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = attributeSlice.actions;

export default attributeSlice.reducer;
