// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { ChartOfAccount, ChartOfAccountCompany, ChartOfAccountState } from './chartOfAccountsInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField, Option } from '../../../components/componentInterface';
import { Company } from '../company/companyInterface';
import { checkIfChartOfAccountStore, getChartOfAccountData, saveChartOfAccountData } from '../../../app/idb/coaOperations';

const initialState: ChartOfAccountState = {
  chartOfAccounts: [],
  chartOfAccount: {
    code: '',
    account_name: '',
    is_active: true,
    is_bank: false,
    is_exchange: false,
    is_cash: false,
    is_reference: false,
    is_transaction: false,
    parent_account_name: '',
    account_type: 'Asset',
    level: 0,
    order: 0,
    parent_account_id: null,
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  nature: {
    isValidToAdd: false,
    account_type: null,
  },
  error: null,
  status: 'idle',
  message: ""
};



// Async thunk for fetching buyers
export const fetchChartOfAccounts = createAsyncThunk(
  'chartOfAccount/fetchChartOfAccounts',
  async (
    { page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE, search = '' }: { page?: number; perPage?: number; search?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(`/coa-list`, {
        params: {
          page,
          perPage,
          search,
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAllChartOfAccountsTree = createAsyncThunk(
  'chartOfAccount/fetchAllChartOfAccountsTree',
  async () => {

    try {
      const response = await axiosInstance.get(`/coa-list-tree-view`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAllChartOfAccountsOnlyTransaction = createAsyncThunk(
  'chartOfAccount/fetchAllChartOfAccountsOnlyTransaction',
  async () => {

    try {

      const {exists, hasData} = await checkIfChartOfAccountStore();
      
      if (!hasData) {
        const response = await axiosInstance.get(`/coa-only-transaction`);
        await saveChartOfAccountData(response.data);
        return response.data;
      } else {
        const data = await getChartOfAccountData();
        return data;
      }

    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAllChartOfAccountsOnlyWithoutTransaction = createAsyncThunk(
  'chartOfAccount/fetchAllChartOfAccountsOnlyWithoutTransaction',
  async () => {

    try {
      const response = await axiosInstance.get(`/coa-only-without-transaction`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchChartOfAccount = createAsyncThunk(
  'chartOfAccount/fetchChartOfAccount',
  async ({ id }: { id: string }, { rejectWithValue }) => {

    try {
      const response = await axiosInstance.get(`/coa-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchLedgerIsApplicableOrNot = createAsyncThunk(
  'chartOfAccount/fetchLedgerIsApplicableOrNot',
  async ({ id, transaction_type }: { id: string, transaction_type: string }, { rejectWithValue }) => {

    try {
      const response = await axiosInstance.get(`/ledger/${id}/${transaction_type}/check-reference`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createChartOfAccount = createAsyncThunk(
  'chartOfAccount/createChartOfAccount',
  async (formState: Omit<ChartOfAccount, 'id'>, { rejectWithValue }) => {
    try {
      debugger
      const { parent_account_id, account_name, description, order, details,
        is_transaction, is_exchange, is_reference, is_cash, is_bank, is_active } = formState.data;

      const attributeData = {
        parent_account_id: parent_account_id.selected.value,
        account_name: account_name.value,
        description: description.value,
        order: order.value,
        is_transaction: is_transaction.value ? 1 : 0,
        is_exchange: is_exchange.value ? 1 : 0,
        is_reference: is_reference.value ? 1 : 0,
        is_cash: is_cash.value ? 1 : 0,
        is_bank: is_bank.value ? 1 : 0,
        is_active: is_active.value ? 1 : 0,
        company_ids: details?.rows.map((row : any) => {
          const newRow: Partial<ChartOfAccountCompany> = {
            id: 0
          };

          row?.map((cell : any) => {
            if (cell.name === 'company_id') {
              newRow.id = cell.type.value as number;
            } 
          })

          return newRow;

        }) || []
      };
      // debugger

      const response = await axiosInstance.post('/coa-save', attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateChartOfAccount = createAsyncThunk(
  'chartOfAccount/updateChartOfAccount',
  async (formState: Omit<ChartOfAccount, 'id'>, { rejectWithValue }) => {
    try {
      debugger
      const { id, parent_account_id, account_name, description, order, details,
        is_transaction, is_exchange, is_reference, is_cash, is_bank, is_active } = formState.data;

      const attributeData = {
        id: id.value,
        parent_account_id: parent_account_id.selected.value,
        account_name: account_name.value,
        description: description.value,
        order: order.value,
        is_transaction: is_transaction.value ? 1 : 0,
        is_exchange: is_exchange.value ? 1 : 0,
        is_reference: is_reference.value ? 1 : 0,
        is_cash: is_cash.value ? 1 : 0,
        is_bank: is_bank.value ? 1 : 0,
        is_active: is_active.value ? 1 : 0,
        company_ids: details?.rows.map((row : any) => {
          const newRow: Partial<ChartOfAccountCompany> = {
            id: 0
          };

          row?.map((cell : any) => {
            if (cell.name === 'company_id') {
              newRow.id = cell.type.value as number;
            } 
          })

          return newRow;

        }) || []
      };
      debugger

      const response = await axiosInstance.put(`/coa-update/${attributeData.id}`, attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteChartOfAccount = createAsyncThunk(
  'chartOfAccount/deleteChartOfAccount',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/coa-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const chartOfAccountSlice = createSlice({
  name: 'chartOfAccount',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChartOfAccounts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchChartOfAccounts.fulfilled, (state, action: PayloadAction<PaginationObject<ChartOfAccount>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchChartOfAccounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllChartOfAccountsOnlyWithoutTransaction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllChartOfAccountsOnlyWithoutTransaction.fulfilled, (state, action: PayloadAction<ChartOfAccount[]>) => {
        state.chartOfAccounts = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllChartOfAccountsOnlyWithoutTransaction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllChartOfAccountsTree.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllChartOfAccountsTree.fulfilled, (state, action: PayloadAction<ChartOfAccount[]>) => {
        state.chartOfAccounts = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllChartOfAccountsTree.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllChartOfAccountsOnlyTransaction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllChartOfAccountsOnlyTransaction.fulfilled, (state, action: PayloadAction<ChartOfAccount[]>) => {
        state.chartOfAccounts = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllChartOfAccountsOnlyTransaction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchChartOfAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchChartOfAccount.fulfilled, (state, action: PayloadAction<ChartOfAccount>) => {
        state.chartOfAccount = action.payload;
        state.loading = false;
      })
      .addCase(fetchChartOfAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchLedgerIsApplicableOrNot.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLedgerIsApplicableOrNot.fulfilled, (state, action: PayloadAction<{ isValidToAdd: boolean, account_type: 'Asset' | 'Liability' | 'Equity' | 'Revenue' | 'Expense' | null }>) => {
        state.nature = action.payload;
        state.loading = false;
      })
      .addCase(fetchLedgerIsApplicableOrNot.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createChartOfAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createChartOfAccount.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createChartOfAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateChartOfAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateChartOfAccount.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateChartOfAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteChartOfAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteChartOfAccount.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteChartOfAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = chartOfAccountSlice.actions;

export default chartOfAccountSlice.reducer;
