export const VENDOR_CREATE_NAVIGATION = "/procurement/vendor-create"
export const VENDOR_UPDATE_NAVIGATION = "/procurement/vendor-update/"
export const VENDOR_LIST_NAVIGATION = "/procurement/vendor-list"

export const HOME_SLDIER_CREATE_NAVIGATION = "/ecommerce/home-slider-create"
export const HOME_SLDIER_UPDATE_NAVIGATION = "/ecommerce/home-slider-update/"
export const HOME_SLDIER_LIST_NAVIGATION = "/ecommerce/home-slider-list"

export const ORDER_UPDATE_NAVIGATION = "/ecommerce/order-update/"
export const ORDER_LIST_NAVIGATION = "/ecommerce/order-list"

export const COURIER_CREATE_NAVIGATION = "/ecommerce/courier-create"
export const COURIER_UPDATE_NAVIGATION = "/ecommerce/courier-update/"
export const COURIER_LIST_NAVIGATION = "/ecommerce/courier-list"

export const SHIPMENT_TRACKING_HISTORY_NAVIGATION = "/ecommerce/shipment-tracking-history/"
export const SHIPMENT_CREATE_NAVIGATION = "/ecommerce/shipment-create"
export const SHIPMENT_UPDATE_NAVIGATION = "/ecommerce/shipment-update/"
export const SHIPMENT_LIST_NAVIGATION = "/ecommerce/shipment-list"

export const FOOTER_LINK_CREATE_NAVIGATION = "/ecommerce/footer-link-create"
export const FOOTER_LINK_UPDATE_NAVIGATION = "/ecommerce/footer-link-update/"
export const FOOTER_LINK_LIST_NAVIGATION = "/ecommerce/footer-link-list"

export const RULE_CREATE_NAVIGATION = "/ecommerce/rule-create"
export const RULE_UPDATE_NAVIGATION = "/ecommerce/rule-update/"
export const RULE_LIST_NAVIGATION = "/ecommerce/rule-list"

export const SUB_BANNER_CREATE_NAVIGATION = "/ecommerce/sub-banner-create"
export const SUB_BANNER_UPDATE_NAVIGATION = "/ecommerce/sub-banner-update/"
export const SUB_BANNER_LIST_NAVIGATION = "/ecommerce/sub-banner-list"

export const DYNAMIC_SECTION_CREATE_NAVIGATION = "/ecommerce/dynamic-section-create"
export const DYNAMIC_SECTION_UPDATE_NAVIGATION = "/ecommerce/dynamic-section-update/"
export const DYNAMIC_SECTION_LIST_NAVIGATION = "/ecommerce/dynamic-section-list"

export const COA_CREATE_NAVIGATION = "/accounts/coa-create"
export const COA_UPDATE_NAVIGATION = "/accounts/coa-update/"
export const COA_LIST_NAVIGATION = "/accounts/coa-list"
export const COA_LIST_TREE_NAVIGATION = "/accounts/coa-list-tree-view"

export const DEPARTMENT_CREATE_NAVIGATION = "/accounts/department-create"
export const DEPARTMENT_UPDATE_NAVIGATION = "/accounts/department-update/"
export const DEPARTMENT_LIST_NAVIGATION = "/accounts/department-list"

export const COST_CENTER_CREATE_NAVIGATION = "/accounts/costcenter-create"
export const COST_CENTER_UPDATE_NAVIGATION = "/accounts/costcenter-update/"
export const COST_CENTER_LIST_NAVIGATION = "/accounts/costcenter-list"

export const VOUCHER_TYPE_CREATE_NAVIGATION = "/accounts/voucher-type-create"
export const VOUCHER_TYPE_UPDATE_NAVIGATION = "/accounts/voucher-type-update/"
export const VOUCHER_TYPE_LIST_NAVIGATION = "/accounts/voucher-type-list"

export const BUSINESS_AREA_CREATE_NAVIGATION = "/accounts/business-area-create"
export const BUSINESS_AREA_UPDATE_NAVIGATION = "/accounts/business-area-update/"
export const BUSINESS_AREA_LIST_NAVIGATION = "/accounts/business-area-list"

export const CURRENCY_CREATE_NAVIGATION = "/accounts/currency-create"
export const CURRENCY_UPDATE_NAVIGATION = "/accounts/currency-update/"
export const CURRENCY_LIST_NAVIGATION = "/accounts/currency-list"

export const VOUCHER_CREATE_NAVIGATION = "/accounts/voucher-create"
export const VOUCHER_UPDATE_NAVIGATION = "/accounts/voucher-update/"
export const VOUCHER_LIST_NAVIGATION = "/accounts/voucher-list"
export const VOUCHER_PRINT_NAVIGATION = "/report/voucher-print/"

export const UNIT_GROUP_CREATE_NAVIGATION = "/inventory/unit-group-create"
export const UNIT_GROUP_UPDATE_NAVIGATION = "/inventory/unit-group-update/"
export const UNIT_GROUP_LIST_NAVIGATION = "/inventory/unit-group-list"

export const UNIT_CREATE_NAVIGATION = "/inventory/unit-create"
export const UNIT_UPDATE_NAVIGATION = "/inventory/unit-update/"
export const UNIT_LIST_NAVIGATION = "/inventory/unit-list"

export const CATEGORY_CREATE_NAVIGATION = "/inventory/category-create"
export const CATEGORY_UPDATE_NAVIGATION = "/inventory/category-update/"
export const CATEGORY_LIST_NAVIGATION = "/inventory/category-list"

export const ATTRIBUTE_GROUP_CREATE_NAVIGATION = "/inventory/attribute-group-create"
export const ATTRIBUTE_GROUP_UPDATE_NAVIGATION = "/inventory/attribute-group-update/"
export const ATTRIBUTE_GROUP_LIST_NAVIGATION = "/inventory/attribute-group-list"

export const ATTRIBUTE_FAMILY_CREATE_NAVIGATION = "/inventory/attribute-family-create"
export const ATTRIBUTE_FAMILY_UPDATE_NAVIGATION = "/inventory/attribute-family-update/"
export const ATTRIBUTE_FAMILY_LIST_NAVIGATION = "/inventory/attribute-family-list"

export const ATTRIBUTE_CREATE_NAVIGATION = "/inventory/attribute-create"
export const ATTRIBUTE_UPDATE_NAVIGATION = "/inventory/attribute-update/"
export const ATTRIBUTE_LIST_NAVIGATION = "/inventory/attribute-list"

export const PRODUCT_CREATE_STEP_ONE_NAVIGATION = "/inventory/product-create-step-one"
export const PRODUCT_CREATE_STEP_TWO_NAVIGATION = "/inventory/product-create-step-two/"
export const PRODUCT_LIST_NAVIGATION = "/inventory/product-list"

export const IDX_MENU_DB = "menu-db"
export const IDX_MENU_STORE = "menuItems"
export const IDX_VOUCHER_TYPE_STORE = "voucherType"
export const IDX_COST_CENTER_STORE = "costCenter"
export const IDX_BUSSINESS_UNIT_STORE = "bussinessUnit"
export const IDX_COA_STORE = "coaList"
export const IDX_CURRENCY_LIST_STORE = "currencyList"
export const MENU_UPDATED_EVENT = "App\\Events\\MenuUpdated";
export const VOUCHER_TYPE_UPDATED_EVENT = "App\\Events\\VoucherTypesUpdated";
export const COST_CENTER_UPDATED_EVENT = "App\\Events\\CostCenterUpdated";
export const BUSSINESS_UNIT_UPDATED_EVENT = "App\\Events\\BusinessUnitUpdated";
export const CHART_OF_ACCOUNT_UPDATED_EVENT = "App\\Events\\ChartOfAccountUpdated";
export const CURRENCY_UPDATED_EVENT = "App\\Events\\CurrencyUpdated";

export const IDX_STORE_LIST = [
    "menuItems",
    "voucherType",
    "costCenter",
    "bussinessUnit",
    "coaList",
    "currencyList",
  ];