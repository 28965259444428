import axiosInstance from "../api/axiosInstance";
import { format } from 'date-fns';

export const formatDate = (
    dateString: string, 
    formatType: 'long' | 'short' | 'db_format' | 'datetime_long' | 'datetime_short' | 'db_format_time'
): string => {
    const date = new Date(dateString);

    const pad = (n: number): string => n.toString().padStart(2, '0');

    switch (formatType) {
        case 'long':
            return `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`;
        case 'short':
            return `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`;
        case 'db_format':
            return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
        case 'datetime_long':
            return `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
        case 'datetime_short':
            return `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()} ${pad(date.getHours())}:${pad(date.getMinutes())}`;
        case 'db_format_time':
            return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
        default:
            throw new Error(`Unsupported format type: ${formatType}`);
    }
};


// Utility function to format date and time
export const formatDateTime = (dateStr: string, formatType: string): string => {
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) return ''; // Handle invalid date

    // Choose the format based on the formatType
    switch (formatType) {
        case 'long':
            return format(date, 'PPpp'); // Example: Jul 29, 2024 at 10:00 AM
        case 'short':
            return format(date, 'Pp'); // Example: 07/29/2024, 10:00 AM
        default:
            return date.toISOString(); // Default ISO format
    }
};

export const formatTime = (date: Date, formatType: '12-hour' | '24-hour'): string => {
    const pad = (n: number): string => n.toString().padStart(2, '0');

    switch (formatType) {
        case '12-hour':
            return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        case '24-hour':
            return `${pad(date.getHours())}:${pad(date.getMinutes())}`;
        default:
            throw new Error(`Unsupported format type: ${formatType}`);
    }
};

export const toTitleCase = (text: string): string => {
    return text
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
 