// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { AttributeGroup, AttributeGroupState } from './attributeGroupInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField } from '../../../components/componentInterface';

const initialState: AttributeGroupState = {
  attributeGroups: [],
  attributeGroup: {
    name: '',
    type: '',
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};

// Async thunk for fetching buyers
export const fetchAttributeGroups = createAsyncThunk(
  'attributeGroup/fetchAttributeGroups',
  async ({ page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE }: { page?: number; perPage?: number }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/attribute-group-list?page=${page}&perPage=${perPage}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAllAttributeGroups = createAsyncThunk(
  'attributeGroup/fetchAllAttributeGroups',
  async () => {
    
    try {
      const response = await axiosInstance.get(`/attribute-group-list-all`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAttributeGroup = createAsyncThunk(
  'attributeGroup/fetchAttributeGroup',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/attribute-group-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createAttributeGroup = createAsyncThunk(
  'attributeGroup/createAttributeGroup',
  async (formState: Omit<AttributeGroup, 'id'>, { rejectWithValue }) => {
    try {

      const { details, name, ...formStateData } = formState.data;

      const attributeGroupData = {
        name: name.value,
        rows: details?.rows.map((row : any) => {
          const newRow: Partial<AttributeGroup> = {
            attribute_id: 0
          };

          row?.map((cell : any) => {
            if (cell.name === 'attribute_id') {
              newRow.attribute_id = cell.type.value as number;
            } 
          })

          return newRow;

        }) || []
      };
      debugger

      const response = await axiosInstance.post('/attribute-group-save', attributeGroupData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateAttributeGroup = createAsyncThunk(
  'attributeGroup/updateAttributeGroup',
  async (formState: Omit<AttributeGroup, 'id'>, { rejectWithValue }) => {
    try {
      debugger
      const { details, id, name, type, ...formStateData } = formState.data;
      
      const attributeData = {
        id: id.value,
        name: name.value,
        rows: details?.rows.map((row : any) => {
          const newRow: Partial<AttributeGroup> = {
            attribute_id: 0
          };

          row?.map((cell : any) => {
            if (cell.name === 'attribute_id') {
              newRow.attribute_id = cell.type.value as number;
            } 
          })

          return newRow;

        }) || []
      };

      const response = await axiosInstance.put(`/attribute-group-update/${attributeData.id}`, attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteAttributeGroup = createAsyncThunk(
  'attributeGroup/deleteAttributeGroup',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/attribute-group-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const attributeSlice = createSlice({
  name: 'attribute',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAttributeGroups.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAttributeGroups.fulfilled, (state, action:  PayloadAction<PaginationObject<AttributeGroup>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchAttributeGroups.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllAttributeGroups.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllAttributeGroups.fulfilled, (state, action:  PayloadAction<AttributeGroup[]>) => {
        state.attributeGroups = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllAttributeGroups.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAttributeGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAttributeGroup.fulfilled, (state, action:  PayloadAction<AttributeGroup>) => {
        state.attributeGroup = action.payload;
        state.loading = false;
      })
      .addCase(fetchAttributeGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createAttributeGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAttributeGroup.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createAttributeGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateAttributeGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAttributeGroup.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateAttributeGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteAttributeGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAttributeGroup.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteAttributeGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = attributeSlice.actions;

export default attributeSlice.reducer;
