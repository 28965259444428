import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPlus } from '@fortawesome/free-solid-svg-icons';
import './SettingsIcon.css'; // Import CSS for custom styling

interface SettingsIconProps {
    onClick: () => void; // Function to handle click event
    className?: string; // Optional class name for additional styling
    style?: React.CSSProperties; // Optional inline styles
}

const SettingsIcon: React.FC<SettingsIconProps> = ({ onClick, className, style }) => {

    const iconStyle: React.CSSProperties = {
        color: 'rgb(178 178 178)', // Set your desired color here
        fontSize: '24px', // Optionally adjust icon size
    };

    return (
        <div className={`settings-icon ${className}`} style={iconStyle} onClick={onClick}>
            <FontAwesomeIcon icon={faCog} spin />
        </div>
    );
};

export default SettingsIcon;
