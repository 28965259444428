// src/App.tsx
import React from 'react';
import AppRoutes from './routes';
import 'toastr/build/toastr.min.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Import your i18n configuration
import './components/Table.css'


const App: React.FC = () => {

  // Load language preference from localStorage or use default language
  const storedLanguage = localStorage.getItem('i18nextLng');
  const initialLanguage = storedLanguage || 'en'; // Default to English if no language is stored

  // Set initial language in i18next
  React.useEffect(() => {
    i18n.changeLanguage(initialLanguage);
  }, [initialLanguage]);

  return (
    <div className="App">
      <I18nextProvider i18n={i18n}>
        <AppRoutes />
      </I18nextProvider>
    </div>
  );
};

export default App;
