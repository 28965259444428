// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { AttributeFamily, AttributeFamilyState } from './attributeFamilyInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField } from '../../../components/componentInterface';

const initialState: AttributeFamilyState = {
  attributeFamilies: [],
  attributeFamily: {
    name: '',
    type: '',
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};

// Async thunk for fetching buyers
export const fetchAttributeFamilies = createAsyncThunk(
  'attributeFamily/fetchAttributeFamilies',
  async ({ page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE }: { page?: number; perPage?: number }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/attribute-family-list?page=${page}&perPage=${perPage}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAllAttributeFamilies = createAsyncThunk(
  'attributeFamily/fetchAllAttributeFamilies',
  async () => {
    
    try {
      const response = await axiosInstance.get(`/attribute-family-list-all`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAttributeFamily = createAsyncThunk(
  'attributeFamily/fetchAttributeFamily',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/attribute-family-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createAttributeFamily = createAsyncThunk(
  'attributeFamily/createAttributeFamily',
  async (formState: Omit<AttributeFamily, 'id'>, { rejectWithValue }) => {
    try {

      const { details, name, ...formStateData } = formState.data;

      const attributeGroupData = {
        name: name.value,
        rows: details?.rows.map((row : any) => {
          const newRow: Partial<AttributeFamily> = {
            attribute_group_id: 0
          };

          row?.map((cell : any) => {
            if (cell.name === 'attribute_group_id') {
              newRow.attribute_group_id = cell.type.value as number;
            } 
          })

          return newRow;

        }) || []
      };
      debugger

      const response = await axiosInstance.post('/attribute-family-save', attributeGroupData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateAttributeFamily = createAsyncThunk(
  'attributeFamily/updateAttributeFamily',
  async (formState: Omit<AttributeFamily, 'id'>, { rejectWithValue }) => {
    try {
      debugger
      const { details, id, name, type, ...formStateData } = formState.data;
      
      const attributeData = {
        id: id.value,
        name: name.value,
        rows: details?.rows.map((row : any) => {
          const newRow: Partial<AttributeFamily> = {
            attribute_group_id: 0
          };

          row?.map((cell : any) => {
            if (cell.name === 'attribute_group_id') {
              newRow.attribute_group_id = cell.type.value as number;
            } 
          })

          return newRow;

        }) || []
      };

      const response = await axiosInstance.put(`/attribute-family-update/${attributeData.id}`, attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteAttributeFamily = createAsyncThunk(
  'attributeFamily/deleteAttributeFamily',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/attribute-family-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const attributeSlice = createSlice({
  name: 'attributeFamily',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAttributeFamilies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAttributeFamilies.fulfilled, (state, action:  PayloadAction<PaginationObject<AttributeFamily>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchAttributeFamilies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllAttributeFamilies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllAttributeFamilies.fulfilled, (state, action:  PayloadAction<AttributeFamily[]>) => {
        state.attributeFamilies = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllAttributeFamilies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAttributeFamily.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAttributeFamily.fulfilled, (state, action:  PayloadAction<AttributeFamily>) => {
        state.attributeFamily = action.payload;
        state.loading = false;
      })
      .addCase(fetchAttributeFamily.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createAttributeFamily.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAttributeFamily.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createAttributeFamily.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateAttributeFamily.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAttributeFamily.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateAttributeFamily.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteAttributeFamily.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAttributeFamily.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteAttributeFamily.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = attributeSlice.actions;

export default attributeSlice.reducer;
