import React from 'react';
import './RadioButton.css'

interface RadioButtonProps {
  label: string;
  value: string;
  checked: boolean;
  onChange: (value: string) => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({ label, value, checked, onChange }) => {
  return (
    <label className="inline-flex items-center">
      <input
        type="radio"
        value={value}
        checked={checked}
        onChange={() => onChange(value)}
        className="peer form-radio text-blue-600
                   appearance-none
                   p-0
                   -webkit-print-color-adjust
                   print-color-adjust
                   inline-block
                   align-middle
                   bg-origin-border
                   -webkit-user-select-none
                   -moz-user-select-none
                   user-select-none
                   flex-shrink-0
                   h-4 w-4
                   bg-white
                   border-gray-400
                   border
                   shadow-none
                   checked:bg-blue-600
                   checked:border-transparent"
      />
      <span className="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold">{label}</span>
    </label>
  );
};

export default RadioButton;
