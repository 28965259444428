// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { Shipment, ShipmentState, ShipmentTracking } from './shipmentInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { formatDate } from '../../../app/utils';

const initialState: ShipmentState = {
  shipments: [],
  shipment: {
    courier_name: '',
    customer_name: '',
    order_no: '',
    courier_code: '',
    courier_id: 0,
    is_mail_sent: false,
    tracking_code: '',
    user_id: 0,
    status: 'Pending',
    total_amount: 0,
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};

export const fetchShipments = createAsyncThunk(
  'shipment/fetchShipments',
  async ({ page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE }: { page?: number; perPage?: number }, { rejectWithValue }) => {

    try {
      const response = await axiosInstance.get(`/shipment-list?page=${page}&perPage=${perPage}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchShipment = createAsyncThunk(
  'shipment/fetchShipment',
  async ({ id }: { id: string }, { rejectWithValue }) => {

    try {
      const response = await axiosInstance.get(`/shipment-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for fetching buyers
export const createShipment = createAsyncThunk(
  'shipment/createShipment',
  async (shipemntData: Omit<Shipment, 'id'>, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/save-shipment', shipemntData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const updateShipment = createAsyncThunk(
  'shipment/updateShipment',
  async (shipemntData: Omit<Shipment, 'id'>, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/update-shipment', shipemntData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const updateTrackingHistory = createAsyncThunk(
  'shipment/updateTrackingHistory',
  async (formState: Omit<Shipment, 'id'>, { rejectWithValue }) => {
    try {
      const { details, id, ...formStateData } = formState.data;

      const shipemntData = {
        id: id.value,
        rows: details?.rows.map((row : any) => {
          const newRow: Partial<ShipmentTracking> = {
            destination: '',
            shipment_date: null,
            shipped_from: '',
            status: 'Pending'
          };

          row?.map((cell : any) => {
            if (cell.name === 'destination') {
              newRow.destination = cell.type.value as string;
            } 
            if (cell.name === 'shipped_from') {
              newRow.shipped_from = cell.type.value;
            } 
            if (cell.name === 'status') {
              newRow.status = cell.type.value;
            } 
            if (cell.name === 'shipment_date') {
              newRow.shipment_date_str = formatDate(cell.type.value, 'db_format_time');
            } 
          })

          return newRow;

        }) || []
      };
      const response = await axiosInstance.post(`/shipments/${shipemntData.id}/tracking`, shipemntData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchShipmentTracking = createAsyncThunk(
  'shipment/fetchShipmentTracking',
  async (shipment_id:string, { rejectWithValue }) => {

    try {
      const response = await axiosInstance.get(`/shipment-tracking-history/${shipment_id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const attributeSlice = createSlice({
  name: 'shipment',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShipments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShipments.fulfilled, (state, action: PayloadAction<PaginationObject<Shipment>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchShipments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchShipment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShipment.fulfilled, (state, action: PayloadAction<Shipment>) => {
        state.shipment = action.payload;
        state.loading = false;
      })
      .addCase(fetchShipment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchShipmentTracking.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShipmentTracking.fulfilled, (state, action: PayloadAction<Shipment>) => {
        state.shipment = action.payload;
        state.loading = false;
      })
      .addCase(fetchShipmentTracking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createShipment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createShipment.fulfilled, (state, action: PayloadAction<any>) => {
        state.shipment = {} as Shipment;
        state.message = "Shipment Entry Done"
        state.loading = false;
      })
      .addCase(createShipment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateShipment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateShipment.fulfilled, (state, action: PayloadAction<any>) => {
        state.shipment = {} as Shipment;
        state.message = "Shipment Data Updated"
        state.loading = false;
      })
      .addCase(updateShipment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateTrackingHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTrackingHistory.fulfilled, (state, action: PayloadAction<string>) => {
        state.message = action.payload
        state.loading = false;
      })
      .addCase(updateTrackingHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = attributeSlice.actions;

export default attributeSlice.reducer;
