// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { Store, StoreState } from './storeInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField } from '../../../components/componentInterface';
import { checkIfCurrencyStore, getCurrencyData, saveCurrencyData } from '../../../app/idb/currencyOperations';

const initialState: StoreState = {
  stores: [],
  store: {
    name: '',
    description: '',
    location: '',
    manager_name: '',
    store_group_id: 0,
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};

// Async thunk for fetching buyers
export const fetchStores = createAsyncThunk(
  'store/fetchStores',
  async ({ page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE }: { page?: number; perPage?: number }, { rejectWithValue }) => {

    try {
      const response = await axiosInstance.get(`/currency-list?page=${page}&perPage=${perPage}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAllStores = createAsyncThunk(
  'store/fetchAllStores',
  async () => {

    try {
      const {exists, hasData} = await checkIfCurrencyStore();
      
      if (!hasData) {
        const response = await axiosInstance.get(`/currency-list-all`);
        await saveCurrencyData(response.data);
        return response.data;
      } else {
        const data = await getCurrencyData();
        return data;
      }

    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchStore = createAsyncThunk(
  'store/fetchStore',
  async ({ id }: { id: string }, { rejectWithValue }) => {

    try {
      const response = await axiosInstance.get(`/currency-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createStore = createAsyncThunk(
  'store/createStore',
  async (formState: Omit<Store, 'id'>, { rejectWithValue }) => {
    try {

      const { details, name, code, symbol, conversion_rate,
        ...formStateData } = formState.data;

      const attributeData = {
        name: name.value,
        code: code.value,
        symbol: symbol.value,
        conversion_rate: conversion_rate.value,
      };

      const response = await axiosInstance.post('/currency-save', attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateStore = createAsyncThunk(
  'store/updateStore',
  async (formState: Omit<Store, 'id'>, { rejectWithValue }) => {
    try {
      debugger
      const { details, id, name, code, symbol, conversion_rate,
        ...formStateData } = formState.data;

      const attributeData = {
        id: id.value,
        name: name.value,
        code: code.value,
        symbol: symbol.value,
        conversion_rate: conversion_rate.value,
      };
      debugger

      const response = await axiosInstance.put(`/currency-update/${attributeData.id}`, attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteStore = createAsyncThunk(
  'store/deleteStore',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/currency-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStores.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStores.fulfilled, (state, action: PayloadAction<PaginationObject<Store>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchStores.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllStores.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllStores.fulfilled, (state, action: PayloadAction<Store[]>) => {
        state.stores = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllStores.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStore.fulfilled, (state, action: PayloadAction<Store>) => {
        state.store = action.payload;
        state.loading = false;
      })
      .addCase(fetchStore.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createStore.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createStore.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateStore.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateStore.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteStore.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteStore.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = storeSlice.actions;

export default storeSlice.reducer;
