// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../api/axiosInstance';
import { Rule, RuleCondition, RuleState } from './ruleInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { formatDate } from '../../../app/utils';

const initialState: RuleState = {
  rules: [],
  rule: {
    name: '',
    type: '',
    conditions: [],
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};

// Async thunk for fetching buyers
export const fetchRules = createAsyncThunk(
  'rule/fetchRules',
  async ({ page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE }: { page?: number; perPage?: number }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/rule-list?page=${page}&perPage=${perPage}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAllRules = createAsyncThunk(
  'rule/fetchAllRules',
  async () => {
    
    try {
      const response = await axiosInstance.get(`/rule-list-all`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchRule = createAsyncThunk(
  'rule/fetchRule',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/rule-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createRule = createAsyncThunk(
  'rule/createRule',
  async (formState: Omit<Rule, 'id'>, { rejectWithValue }) => {
    try {
      debugger
      const { details, name, type,  } = formState.data;

      const attributeData = {
        name: name.value,
        type: type.value,
        rows: details?.rows.map((row : any) => {
          const newRow: Partial<RuleCondition> = {
            field: '',
            operator: '',
            category_id: 0,
            date_value: null,
            date_time_value: null,
            value: '',
            attribute_value: '',
            number_value: 0,
            option_id: 0,
          };

          row?.map((cell : any) => {
            if (cell.name === 'field') {
              newRow.field = cell.type.value as string;
            } else if (cell.name === 'operator') {
              newRow.operator = cell.type.value as string;
            } else if (cell.name === 'category_id') {
              newRow.category_id = cell.type.selected ? cell.type.selected.value || 0 as number : 0;
            } else if (cell.name === 'date_value') {
              newRow.date_value = formatDate(cell.type.value, 'db_format')
            } else if (cell.name === 'date_time_value') {
              newRow.date_time_value = formatDate(cell.type.value, 'db_format_time')
            } else if (cell.name === 'attribute_value') {
              newRow.attribute_value = cell.type.value as string;
            } else if (cell.name === 'option_id') {
              newRow.option_id = cell.type.selected ? cell.type.selected.value || 0 as number : 0;
            } else if (cell.name === 'number_value') {
              newRow.number_value = cell.type.value as number;
            }
          })

          return newRow;

        }) || []
      };
      debugger

      const response = await axiosInstance.post('/rule-save', attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateRule = createAsyncThunk(
  'rule/updateRule',
  async (formState: Omit<Rule, 'id'>, { rejectWithValue }) => {
    try {
      
      const { details, id, name, type, } = formState.data;
      debugger
      const attributeData = {
        id: id.value,
        name: name.value,
        type: type.value,
        rows: details?.rows.map((row : any) => {
          const newRow: Partial<RuleCondition> = {
            field: '',
            operator: '',
            category_id: 0,
            date_value: null,
            date_time_value: null,
            value: '',
            attribute_value: '',
            number_value: 0,
            option_id: 0,
          };

          row?.map((cell : any) => {
            debugger
            if (cell.name === 'field') {
              newRow.field = cell.type.value as string;
            } else if (cell.name === 'operator') {
              newRow.operator = cell.type.value as string;
            } else if (cell.name === 'category_id') {
              newRow.category_id = cell.type.selected ? cell.type.selected.value || 0 as number : 0;
            } else if (cell.name === 'date_value') {
              newRow.date_value = formatDate(cell.type.value, 'db_format')
            } else if (cell.name === 'date_time_value') {
              newRow.date_time_value = formatDate(cell.type.value, 'db_format_time')
            } else if (cell.name === 'attribute_value') {
              newRow.attribute_value = cell.type.value as string;
            } else if (cell.name === 'option_id') {
              newRow.option_id = cell.type.selected ? cell.type.selected.value || 0 as number : 0;
            } else if (cell.name === 'number_value') {
              newRow.number_value = cell.type.value as number;
            }
          })

          return newRow;

        }) || []
      };

      const response = await axiosInstance.put(`/rule-update/${attributeData.id}`, attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteRule = createAsyncThunk(
  'rule/deleteRule',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/rule-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const attributeSlice = createSlice({
  name: 'rule',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRules.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRules.fulfilled, (state, action:  PayloadAction<PaginationObject<Rule>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchRules.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllRules.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllRules.fulfilled, (state, action:  PayloadAction<Rule[]>) => {
        state.rules = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllRules.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchRule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRule.fulfilled, (state, action:  PayloadAction<Rule>) => {
        state.rule = action.payload;
        state.loading = false;
      })
      .addCase(fetchRule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createRule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createRule.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createRule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateRule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateRule.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateRule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteRule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteRule.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteRule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = attributeSlice.actions;

export default attributeSlice.reducer;
