import toastr from 'toastr';

class ToastrService {
  constructor() {
    toastr.options = {
      positionClass: 'toast-bottom-right', 
      closeButton: true,
      progressBar: true,
      timeOut: 1200
    };
  }

  success(message: string, title?: string) {
    toastr.success(message, title);
  }

  error(message: string, title?: string) {
    toastr.error(message, title);
  }

  warning(message: string, title?: string) {
    toastr.warning(message, title);
  }

  info(message: string, title?: string) {
    toastr.info(message, title);
  }
}

export default new ToastrService();
