import React from 'react';
import './SkeletonLoader.css'; // Example CSS for styling the skeleton loader

const SkeletonLoader: React.FC = () => {
  return (
    <div className="skeleton-loader">
      <div className="skeleton-block bg-gray-200 dark:bg-colorBlack"></div>
      <div className="skeleton-block bg-gray-200 dark:bg-colorBlack"></div>
      <div className="skeleton-block bg-gray-200 dark:bg-colorBlack"></div>
      <div className="skeleton-block bg-gray-200 dark:bg-colorBlack"></div>
      <div className="skeleton-block bg-gray-200 dark:bg-colorBlack"></div>
      <div className="skeleton-block bg-gray-200 dark:bg-colorBlack"></div>
      <div className="skeleton-block bg-gray-200 dark:bg-colorBlack"></div>
      <div className="skeleton-block bg-gray-200 dark:bg-colorBlack"></div>
      <div className="skeleton-block bg-gray-200 dark:bg-colorBlack"></div>
      <div className="skeleton-block bg-gray-200 dark:bg-colorBlack"></div>
      <div className="skeleton-block bg-gray-200 dark:bg-colorBlack"></div>
      <div className="skeleton-block bg-gray-200 dark:bg-colorBlack"></div>
      <div className="skeleton-block bg-gray-200 dark:bg-colorBlack"></div>
      <div className="skeleton-block bg-gray-200 dark:bg-colorBlack"></div>
      <div className="skeleton-block bg-gray-200 dark:bg-colorBlack"></div>
      <div className="skeleton-block bg-gray-200 dark:bg-colorBlack"></div>
      <div className="skeleton-block bg-gray-200 dark:bg-colorBlack"></div>
      <div className="skeleton-block bg-gray-200 dark:bg-colorBlack"></div>
    </div>
  );
};

export default SkeletonLoader;
