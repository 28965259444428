// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import authReducer from '../modules/auth/authSlice';
import menuReducer from '../modules/auth/menuSlice';

import homeSliderReducer from '../modules/ecommerce/homeSlider/homeSliderSlice';
import orderReducer from '../modules/ecommerce/orderList/orderListSlice';
import courierReducer from '../modules/ecommerce/courier/courierSlice';
import shipmentReducer from '../modules/ecommerce/shipment/shipmentSlice';
import footerLinkPagesReducer from '../modules/ecommerce/footerLinkPages/footerLinkPagesSlice';
import ruleReducer from '../modules/ecommerce/rule/ruleSlice';
import subBannerReducer from '../modules/ecommerce/subBanner/subBannerSlice';
import dynamicPageReducer from '../modules/ecommerce/dynamicPage/dynamicPageSlice';
import siteSettingsReducer from '../modules/ecommerce/siteSettings/siteSettingsSlice';

import chartOfAccountsReducer from '../modules/accounts/chartOfAccounts/chartOfAccountsSlice';
import companyReducer from '../modules/accounts/company/companySlice';
import departmentReducer from '../modules/accounts/department/departmentSlice';
import costcenterReducer from '../modules/accounts/costcenter/costcenterSlice';
import voucherTypeReducer from '../modules/accounts/voucherType/voucherTypeSlice';
import voucherReducer from '../modules/accounts/voucherEntry/voucherSlice';
import businessAreaReducer from '../modules/accounts/bussinessArea/businessAreaSlice';
import currencyReducer from '../modules/accounts/currency/currencySlice';

import storeGroupReducer from '../modules/inventory/storeGroup/storeGroupSlice';
import storeReducer from '../modules/inventory/store/storeSlice';
import attributeReducer from '../modules/inventory/attribute/attributeSlice';
import categoryReducer from '../modules/inventory/category/categorySlice';
import attributeGroupReducer from '../modules/inventory/attributeGroup/attributeGroupSlice';
import attributeFamilyReducer from '../modules/inventory/attributeFamily/attributeFamilySlice';
import unitGroupReducer from '../modules/inventory/unitGroup/unitGroupSlice';
import unitReducer from '../modules/inventory/unit/unitSlice';
import productReducer from '../modules/inventory/product/productSlice';

import paymentTermReducer from '../modules/procurement/paymentTerms/paymentTermsSlice';
import vendorReducer from '../modules/procurement/vendor/vendorSlice';
import customerReducer from '../modules/procurement/customer/customerSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    menu: menuReducer,
    attribute: attributeReducer,
    category: categoryReducer,
    attributeGroup: attributeGroupReducer,
    product: productReducer,
    attributeFamily: attributeFamilyReducer,
    vendor: vendorReducer,
    currency: currencyReducer,
    homeSlider: homeSliderReducer,
    order: orderReducer,
    courier: courierReducer,
    shipment: shipmentReducer,
    customer: customerReducer,
    footerLink: footerLinkPagesReducer,
    rule: ruleReducer,
    subBanner: subBannerReducer,
    dynamicPage: dynamicPageReducer,
    siteSettings: siteSettingsReducer,
    unitGroup: unitGroupReducer,
    unit: unitReducer,

    chartOfAccount: chartOfAccountsReducer,
    company: companyReducer,
    department: departmentReducer,
    costCenter: costcenterReducer,
    voucherType: voucherTypeReducer,
    voucher: voucherReducer,
    businessArea: businessAreaReducer,
    storeGroup: storeGroupReducer,
    store: storeReducer,
    paymentTerm: paymentTermReducer,
    // Add other reducers as needed
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

// Define the RootState type based on the store's reducer
export type RootState = ReturnType<typeof store.getState>;

// Define AppDispatch type based on the store's dispatch
export type AppDispatch = typeof store.dispatch;

export default store;
