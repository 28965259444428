// src/utils/idb/menuOperations.ts
import { initDB } from './dbConnection';
import { IDX_COA_STORE } from '../URL/student-information';
import { ChartOfAccount } from '../../modules/accounts/chartOfAccounts/chartOfAccountsInterface';

const STORE_NAME = IDX_COA_STORE;

export const saveChartOfAccountData = async (menuData: ChartOfAccount[]) => {
    
    const db = await initDB();
    for (const item of menuData) {
        const tx = db.transaction(STORE_NAME, 'readwrite');
        const store = tx.objectStore(STORE_NAME);
        await store.put({ ...item, });
        await tx.done;
    }
};

export const getChartOfAccountData = async (): Promise<ChartOfAccount[]> => {
    const db = await initDB();
    const store = db.transaction(STORE_NAME).objectStore(STORE_NAME);
    const allItems: ChartOfAccount[] = await store.getAll();

    return allItems;
};

// Check if store exists and contains data
export const checkIfChartOfAccountStore = async () => {
    const db = await initDB();
    const storeNames = db.objectStoreNames;

    // Check if the store exists
    if (!storeNames.contains(STORE_NAME)) {
        return { exists: false, hasData: false };
    }

    const store = db.transaction(STORE_NAME).objectStore(STORE_NAME);
    const allItems: ChartOfAccount[] = await store.getAll();

    // Check if there is any data in the store
    const hasData = allItems.length > 0;

    return { exists: true, hasData };
};

export const clearChartOfAccountData = async () => {
    const db = await initDB();
    await db.clear(STORE_NAME);
};
