// src/utils/idb/menuOperations.ts
import { initDB } from './dbConnection';
import { MenuItem } from '../../modules/auth/menuInterface';
import { IDX_MENU_STORE } from '../URL/student-information';

const STORE_NAME = IDX_MENU_STORE;

export const saveMenuData = async (menuData: MenuItem[]) => {
    const db = await initDB();
    const addMenuItemsRecursive = async (items: MenuItem[], parentId: number | null = null) => {
        for (const item of items) {
            const tx = db.transaction(STORE_NAME, 'readwrite');
            const store = tx.objectStore(STORE_NAME);
            await store.put({ ...item, parent_id: parentId });
            await tx.done;

            if (item.child && item.child.length > 0) {
                await addMenuItemsRecursive(item.child, item.id);
            }
        }
    };
    await addMenuItemsRecursive(menuData);
};

export const getMenuData = async (moduleName: string): Promise<MenuItem[]> => {
    const db = await initDB();
    const store = db.transaction(IDX_MENU_STORE).objectStore(STORE_NAME);
    const allItems: MenuItem[] = await store.getAll();

    const buildHierarchy = (items: MenuItem[], parentId: number | null = null): MenuItem[] => {
        return items
            .filter(item => item.parent_id === parentId && item.module_name.toLowerCase() === moduleName)
            .map(item => ({
                ...item,
                child: buildHierarchy(items, item.id),
            }));
    };
    return buildHierarchy(allItems);
};

// Check if store exists and contains data
export const checkIfStoreExistsAndHasData = async () => {
    const db = await initDB();
    const storeNames = db.objectStoreNames;

    // Check if the store exists
    if (!storeNames.contains(STORE_NAME)) {
        return { exists: false, hasData: false };
    }

    const store = db.transaction(IDX_MENU_STORE).objectStore(IDX_MENU_STORE);
    const allItems: MenuItem[] = await store.getAll();

    // Check if there is any data in the store
    const hasData = allItems.length > 0;

    return { exists: true, hasData };
};

export const clearMenuData = async () => {
    const db = await initDB();
    await db.clear(IDX_MENU_STORE);
};
